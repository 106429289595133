import { SET_CURRENT_DRAFT } from '../action/action.config'

const initialState = {
    // currentSite: "",    
    currentTitle: "",    
    currentDescription: "",   
    currentIndex: 0 
}

export const draftReducer = (state = initialState, action: any) => {
    const { type, payload } = action
    switch (type) {
        case SET_CURRENT_DRAFT:
            return {
                ...state,
                // currentSite: payload.site,
                currentTitle: payload.title,
                currentDescription: payload.description,
                currentIndex: payload.index
            }
        default:
            return state
    }
}
