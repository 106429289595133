import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
// import MobileDiv from './MobileDiv'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import VideosList from './components/VideosList'
import Users from './components/Users'
import { scrape_product_links_and_their_videos_from_one_link } from 'modules/api/market-api'
import history from 'modules/app/components/history'
import jwt_decode from 'jwt-decode'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const Videos_Search = (props: any) => {
    const [ isLoading, setIsLoading ] = React.useState<boolean>(false)
    const [value, setValue] = React.useState('1')
    const [ keyword, setKeyword ] = React.useState<string>("")

    const [ total_array, setTotalArray ] = React.useState<any>([])

    const [ records_page, setRecordsPage ] = React.useState(0)
    const [ records_rows_per_page, setRecordsRowsPerPage ] = React.useState(10)
    const [ records_count, setRecordsCount ] = React.useState(0)
    const [ rows_records, setRowsRecords ] = React.useState([
        {
            link: '',
            videos_for_this_product: '',
            videos_for_related_products: '',
        },
    ])

    // const [ users_page, setUsersPage ] = React.useState(0)
    // const [ users_rows_per_page, setUsersRowsPerPage ] = React.useState(10)
    // const [ users_count, setUsersCount ] = React.useState(0)
    // const [ rows_users, setRowsUsers ] = React.useState([
    //     {
    //         no: 0,
    //         username: 'no',
    //         gclid: 'no',
    //         msclkid: 'no',
    //         amazon_tracking_id: 'no',
    //         time_stamp: ''
    //     },
    // ])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }

    useEffect(() => {
        if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
            const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
            if ( decoded.id == localStorage.getItem('userDbId') ) {
                
            } else {
                history.push(`/signin`)
            }
        } else {
            history.push(`/signin`)
        }
    }, [])

    useEffect(() => {
        if ( total_array.length > 0) {
            setIsLoading(true)
            let tmp_arr = JSON.parse(JSON.stringify(total_array));
            let extract_videos = tmp_arr.splice((records_page * records_rows_per_page), records_rows_per_page);
            setRowsRecords(extract_videos)
            setRecordsCount(total_array.length)
    
            setIsLoading(false)
        }
    }, [total_array, records_page, records_rows_per_page])

    // useEffect(() => {
    //     if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
    //         const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
    //         if ( decoded.id == localStorage.getItem('userDbId') ) {
    //             setIsLoading(true)
    //             getUsers(users_page, users_rows_per_page)
    //                 .then((result:any) => {
    //                     if ( result.status === 'success' ) {
    //                         setIsLoading(false)
    //                         setUsersCount(result.data.count)
    //                         setRowsUsers(result.data.users)
    //                     } else if ( result.status === 'error' ) {
    //                         setIsLoading(false)
    //                         history.push('/admin/signin')
    //                     }
                        
    //                 })
    //         } else {
    //             history.push(`/admin/signin`)
    //         }
    //     } else {
    //         history.push(`/admin/signin`)
    //     }
    // }, [users_page, users_rows_per_page])

    const handleClickShowPassword = () => {
        if ( keyword != "") {
            console.log('click search ... ')
            console.log(keyword)

            setIsLoading(true)
            scrape_product_links_and_their_videos_from_one_link(keyword)
                .then((result:any) => {
                    if ( result.status === 'success' ) {
                        setIsLoading(false)
                        let tmp_array_more = result.data.result_array_more
                        let tmp_array_less = result.data.result_array_less
                        let tmp_array = tmp_array_more.concat(tmp_array_less)
                        setTotalArray(tmp_array)
                        // setRecordsCount(result.data.count)
                    } else if ( result.status === 'error' ) {
                        setIsLoading(false)
                        // history.push('/signin')
                    }
                })
         }
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ( e.key == 'Enter' && keyword != "") {
           console.log(keyword)

           setIsLoading(true)
           scrape_product_links_and_their_videos_from_one_link(keyword)
                .then((result:any) => {
                    if ( result.status === 'success' ) {
                        setIsLoading(false)
                        let tmp_array_more = result.data.result_array_more
                        let tmp_array_less = result.data.result_array_less
                        let tmp_array = tmp_array_more.concat(tmp_array_less)
                        setTotalArray(tmp_array)
                        // setRecordsCount(result.data.count)
                    } else if ( result.status === 'error' ) {
                        setIsLoading(false)
                        // history.push('/signin')
                    }
                })
        }
    }

    return (
        <>
            <CustomWrapperTable>
                {
                    isLoading === true ? 
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit"/>
                        <p>&nbsp;&nbsp; Loading ...</p>
                    </Backdrop> : <></>
                }
                <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '0px!important'}}>
                    <CustomGrid item container spacing={0} sm={12} className="grid_main" sx={{background: 'transparent!important'}} >
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <CustomDiv className={'input_div'}>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={'text'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                                <SearchIcon /> 
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label=""
                                    style={{width: '95%'}}
                                    value={keyword}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </CustomDiv>
                            <VideosList 
                                page={records_page} 
                                setPage={setRecordsPage} 
                                rowsPerPage={records_rows_per_page} 
                                setRowsPerPage={setRecordsRowsPerPage} 
                                rows={rows_records} 
                                count={records_count} 
                            />
                            {/* <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Records" value="1" />
                                        <Tab label="Users" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1"><Records page={records_page} setPage={setRecordsPage} rowsPerPage={records_rows_per_page} setRowsPerPage={setRecordsRowsPerPage} rows={rows_records} count={records_count} /></TabPanel>
                                <TabPanel value="2"><Users page={users_page} setPage={setUsersPage} rowsPerPage={users_rows_per_page} setRowsPerPage={setUsersRowsPerPage} rows={rows_users} count={users_count} /></TabPanel>
                            </TabContext> */}
                        </Box>
                    </CustomGrid>
                </CustomGrid>
            </CustomWrapperTable>

            {/* <MobileDiv /> */}
            {/* <MobileDiv value={value} setValue={setValue} rows_records={rows_records} rows_users={rows_users} /> */}
        </>                                                               
    );
};

const CustomDiv = styled.div`
    &.input_div {
        display: flex;
        justify-content: center;
        padding-bottom: 10px!important;
    }
`

const CustomWrapperTable = styled.div`
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (max-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`
const CustomGrid = styled(Grid)`
  background-color: white!important;

  padding: 8px 20px;
  margin: 0px!important;

  .grid_main{
    display: flex;
    justify-content: start;
  }

`
export default Videos_Search