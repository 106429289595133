import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
 
  interface Column {
    id: 'link' | 'videos_for_this_product' | 'videos_for_related_products';
    label: string;
    minWidth?: string;
    align?: 'left' | 'center' | 'right';
  }
  
  const columns: readonly Column[] = [
    { 
      id: 'link', 
      label: 'Link', 
      minWidth: '50%',
      align: 'left'
    },
    { 
      id: 'videos_for_this_product', 
      label: 'Videos for this product', 
      minWidth: '20%',
      align: 'center'
    },
    {
      id: 'videos_for_related_products',
      label: 'Videos for related product',
      minWidth: '20%',
      align: 'center'
    }
  ];
  
  interface Data {
    link: string;
    videos_for_this_product: string;
    videos_for_related_products: string;
  }

  interface Props {
    page: number,
    setPage: any,
    rowsPerPage: number,
    setRowsPerPage: any,
    count: number,
    rows: Data[]
  }

  const VideosList = (props: Props) => {
    const { page, setPage, rowsPerPage, setRowsPerPage, rows, count } = props
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const goto_product_page = (e:any, product_link:string) => {
      e.stopPropagation()
      window.open(product_link, "_blank");
    }

    return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: '80vh' }}>
              <Table stickyHeader aria-label="sticky table" sx={{ tableLayout: 'fixed', overflowWrap: 'break-word'}}>
                <TableHead>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          // align={column.align}
                          align={'center'}
                          style={{ width: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                      rows.length === 0 ? '' :
                      // rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      rows.map((row: Data, index: number) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{cursor: 'pointer'}} onClick={(e:any) => {goto_product_page(e, row['link'])}}>
                                {
                                  columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                          {value}
                                        </TableCell>
                                    );
                                  })
                                }
                            </TableRow>
                            );
                        })
                    }
                </TableBody>
              </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              // count={rows.length}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </>                                                               
    );
};

export default VideosList