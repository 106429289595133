import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Grid, Paper, List } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import history from 'modules/app/components/history'
import { get_all_keywords } from 'modules/api/market-api'
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import jwt_decode from 'jwt-decode'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog_delete from './Dialog_delete'
import Dialog_edit from './Dialog_edit'

const Manager = (props: any) => {
    const [ keyword, setKeyword ] = useState<string>("")
    const [ keyword_array, setKeyword_array ] = useState<any>([])
    const [ delDialogOpen, setDelDialogOpen ] = React.useState<Boolean>(false)
    const [ delKeyword, setDelKeyword ] = React.useState<string>('')
    const [ ediDialogOpen, setEdiDialogOpen ] = React.useState<Boolean>(false)
    const [ ediKeyword, setEdiKeyword ] = React.useState<string>('')


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ( e.key == 'Enter' && keyword != "") {
            let keyword1 = keyword.replaceAll(' ', '-')
            history.push(`/qa_manager/${keyword1}`)
        }
    }

    const gotoQaPage = (link: string) => {
        history.push(`/qa_manager/${link}`)
    }

    const editKeyword = (e:any, keyword: string) => {
        e.stopPropagation()
        setEdiKeyword(keyword)
        setEdiDialogOpen(true)
    }

    const deleteKeyword = (e:any, keyword: string) => {
        e.stopPropagation()
        setDelKeyword(keyword)
        setDelDialogOpen(true)
    }

    useEffect(() => {
        if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
            const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
            if ( decoded.id == localStorage.getItem('userDbId') ) {
                get_all_keywords()
                    .then(result => {
                        setKeyword_array(result.data.data)
                    })
            } else {
                history.push(`/signin`)
            }
            
        } else {
            history.push(`/signin`)
        }
    },[])

    return (
        <>
            <CustomWrapperInit>
                <CustomGrid id="mainGrid" container spacing={0}>
                    <CustomGrid item sm={12}>

                    </CustomGrid>
                    <CustomGrid item sm={12} className="content_grid">
                        <div>
                            <div className='top_div'>
                                <div className='top_div_main'>
                                    <div className='top_div_top'>FAQ</div>
                                    <div className='top_div_bottom'>Frequently Asked Questions</div>
                                </div>
                            </div>
                            <div className='middle_div'>
                                {/* <TextField id="outlined-basic" variant="outlined" placeholder='Please input the keyword for questions and answers' sx={{width: '100%'}}/> */}
                                <TextField 
                                    variant="outlined" 
                                    label='Please input the keyword for questions and answers' 
                                    sx={{width: '95%'}}
                                    value={keyword}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <div className='bottom_div'>
                                <Box className="main_paper">
                                    <List component="nav" aria-label="secondary mailbox folder">
                                        {
                                            keyword_array.map((ele: any, index: number) => {
                                                return <ListItemButton key={index} className='content_list' onClick={() => {gotoQaPage(ele.link)}}>
                                                            <ListItemText primary={ele.link.replaceAll('-', ' ')} />
                                                            <ModeEditOutlinedIcon className='edit_icon' onClick={(e) => {editKeyword(e, ele.link.replaceAll('-', ' '))}}/>&nbsp;&nbsp;
                                                            <DeleteOutlineOutlinedIcon className='delete_icon' onClick={(e) => {deleteKeyword(e, ele.link.replaceAll('-', ' '))}}/>
                                                        </ListItemButton>
                                            })
                                        }
                                    </List>
                                </Box>
                            </div>
                        </div>
                    </CustomGrid>
                    <CustomGrid item sm={12}>

                    </CustomGrid>
                </CustomGrid>
            </CustomWrapperInit>
            <Dialog_delete open={delDialogOpen} setOpen={setDelDialogOpen} keyword={delKeyword} setKeyword_array={setKeyword_array} />
            <Dialog_edit open={ediDialogOpen} setOpen={setEdiDialogOpen} keyword={ediKeyword} setKeyword_array={setKeyword_array} />
        </>                                                             
    );
};

const CustomAccordion = styled(Accordion)`
    width: 100%!important;
    .italic_style {
        font-style: italic;
    }
`
const CustomTypography = styled(Typography)`
    font-size: 16px!important;
    font-weight: 600!important;
`
const Toptitle = styled.span`
    font-size: 32px;
    font-weight: 700;
    margin: 10px 0;
`

const CustomGrid = styled(Grid)`
    &#mainGrid {
        margin-bottom: 5px;
        min-height: 85vh;
    }
    &.content_grid {
        display: flex!important;
        justify-content: center!important;

        .top_div {
            // background-color: #dff0f74d;
            // height: 690px;
            position: relative;
            display: flex;
            -webkit-box-align: center;
            // align-items: center;
            .top_div_main {
                padding-left: 24px;
                padding-right: 24px;
                width: 100%;
                margin-left: auto;
                box-sizing: border-box;
                margin-right: auto;
                display: block;
                .top_div_top {
                    // color: #05426c;
                    text-align: center;
                    font-family: Roboto,sans-serif;
                    font-weight: 400;
                    line-height: 1.2;
                    letter-spacing: 6px;
                    font-size: 1.25rem;
                    padding-top: 20px;
                }
                .top_div_bottom {
                    line-height: 32px;
                    font-size: 2.75rem;
                    font-family: Oswald,sans-serif;
                    font-weight: 700;
                    line-height: 1.1;
                    letter-spacing: 0.01em;
                    text-align: center;
                    max-width: 728px;
                    padding: 10px 0 25px;
                    margin: 0 auto;
                    box-sizing: inherit;
                }
            }
        }
        .middle_div {
            position: relative;
            display: flex;
            justify-content: center;
        }
        .bottom_div {
            // position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 10px!important;
            .main_paper{
                width: 95%!important;
                max-height: 200px!important;
                overflow: auto!important;
                box-sizing: border-box!important;
            }
            .content_list{
                padding-left: 15px!important;
                border-bottom: 1px solid gray;
                .edit_icon:hover {
                    color: #0b9bef;
                }
                .delete_icon:hover {
                    color: #ef1634;
                }
            }
            // .content_list:hover {
            //     background-color: #a885e6!important;
            //     color: white!important;
            //     cursor: pointer!important;
            // }
        }
    }
`

const CustomWrapperInit = styled.div`
    // min-height: 100vh;
    @media (max-width: ${screenSizes.mediaM}px) {
        // display: none;
    }
    
`
export default Manager