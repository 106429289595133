import { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router'
import { FlexSBCont, FlexCont, CustomSpan, BoldSpan } from 'shared/styles/styled'
import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'
import {
  MobileNavWrapper,
  MobileNavCont,
  MobNavHeader,
  MobNavItemCont,
  MobNavItem,
  MobNavDivider,
} from './style'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import Backdrop from '@mui/material/Backdrop';
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentProductUrl } from 'logic/action/base.action'
import history from 'modules/app/components/history'
import jwt_decode from 'jwt-decode'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';

const MobileSideNav = (props: any) => {
  const { showSideNav, setShowSideNav } = props
  const dispatch = useDispatch()

  useEffect(() => {

  }, [])

  const changeProduct = (product: any) => {
    // dispatch(setCurrentProductName(category))
    let product_url = product.toLowerCase().split(' ').join('-')
    // console.log(product_url)
    history.push(`/${product_url}`)
    setShowSideNav(false)
  }

  const SignIn = () => {
    setShowSideNav(false)
    history.push(`/signin`)
  }

  const SignOut = () => {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('userDbId')
    setShowSideNav(false)
    history.push(`/`)
  } 

  const renderSignButton = () => {
    if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
      const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
      if ( decoded.id == localStorage.getItem('userDbId') ) {
        return <Button variant="outlined" className="non_upper" onClick={SignOut}>Sign Out</Button>
      } else {
        return <Button variant="outlined" className="non_upper" onClick={SignIn}>Sign In</Button>
      }
    } else {
        return <Button variant="outlined" className="non_upper" onClick={SignIn}>Sign In</Button>
    }
  }

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 99 }}
      open={showSideNav}
      onClick={() => { setShowSideNav(false) }}
    >
        
    <MobileNavWrapper open={showSideNav} onClick={(e: any) => { e.stopPropagation() }}>
      <MobileNavCont>
        <MobNavHeader>
          <FlexSBCont>
            <FlexCont>
              {/* <img src={require('assets/logo_avatar3.png').default} alt="" />
              &nbsp;<CustomSpan ><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</CustomSpan> */}
            </FlexCont>
            <ClearIcon className="cross-icon" onClick={() => setShowSideNav(false)}/>
          </FlexSBCont>
        </MobNavHeader>
        <MobNavItemCont>
          <CustomList className="main_list">
            <ListItem >
              {
                renderSignButton()
              }
            </ListItem>
          </CustomList>
          <MobNavDivider />
        </MobNavItemCont>
      </MobileNavCont>
    </MobileNavWrapper>
    </Backdrop>

  )
}

const CustomList = styled(List)`
  .non_upper {
    width: 100%!important;
    text-transform: none!important;
  }
`

export default withRouter(MobileSideNav)
