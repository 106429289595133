import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Grid, Paper, List } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import history from 'modules/app/components/history'
import { get_all_articles } from 'modules/api/market-api'
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import jwt_decode from 'jwt-decode'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import Dialog_delete from './Dialog_delete'
import Dialog_edit from './Dialog_edit'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { setCurrentDraftUpdate } from 'logic/action/base.action'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from '@mui/material/DialogContentText';

const Wix_Published = (props: any) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: any) => state)
    const [ site, setSite ] = useState<string>('');
    const handleChange = (event: SelectChangeEvent) => {
        setSite(event.target.value);
    };

    const [ publishied_article_array, setPub_art_array ] = useState<any>([])

    const [ isLoading, setIsLoading ] = useState<boolean>(false)

    const editKeyword = (e:any, article: any, index: number) => {
        // e.stopPropagation()
        // console.log(article)
        let body_html = article.body_html.replaceAll('<p>', '')
        body_html = body_html.replaceAll('</p>', '\n\n')
        dispatch(setCurrentDraftUpdate(site, article.blog_id, article.id, article.title, body_html, article.image?article.image.src:''))
        history.push(`/wix/update`)
    }

    useEffect(() => {
        // console.log('published_wix')
    },[])

    useEffect(() => {
        if( site == '' ) {
            setPub_art_array([])
        } else {
            setIsLoading(true)
            get_all_articles(site)
                .then(result => {
                    // let topicDescription = result.data.topic_description
                    // console.log(topicDescription)
                    // setTopicDescription(topicDescription)
                    // console.log(result.data)
                    setPub_art_array(result.data.articles)
                    setIsLoading(false)
                    // setEditIndex(index)
                    // setEditDialogOpen(true)

                    // dispatch(setCurrentDraft(topicTitle, topicDescription, index))
                    // history.push(`/wix/draft`)
                })
                .catch(error => {
                    setIsLoading(false)
                    // console.log(error)
                })
        }
    },[site])

    return (
        isLoading == true ? 
        <> 
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
                <p>&nbsp;&nbsp; Loading Data ...</p>
            </Backdrop>
            <CustomWrapperInit>
                <CustomGrid id="mainGrid" container spacing={0}>
                    
                    <CustomGrid item xs={12} sm={12} className="content_grid flex_direction_column">
                            
                    </CustomGrid>
                   
                </CustomGrid>
                
            </CustomWrapperInit>
        </> :
        <>
            <CustomWrapperInit>
                <CustomGrid id="mainGrid" container spacing={0}>
                    
                    <CustomGrid item xs={12} sm={12} className="content_grid flex_direction_column">
                            <div className='middle_div'>
                                <CustomGrid item container spacing={0}>
                                    <CustomGrid item xs={12} sm={1} className="site_label">
                                        <DialogContentText>
                                            site:
                                        </DialogContentText>
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={11} >
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={site}
                                            onChange={handleChange}
                                            sx={{ mb: 1, width: '100%' }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {/* <MenuItem value="ourmonet">Ourmonet</MenuItem> */}
                                            <MenuItem value="hairme">HAIR ME</MenuItem>
                                            <MenuItem value="remotion">REMOTION</MenuItem>
                                            <MenuItem value="home_design_perspect">Home Design Perspect</MenuItem>
                                        </Select>
                                    </CustomGrid>
                                </CustomGrid>
                            </div>
                            <div className='bottom_div'>
                                <Box className="main_paper">
                                    <List component="nav" aria-label="secondary mailbox folder">
                                        {
                                            publishied_article_array.map((ele: any, index: number) => {
                                                return <ListItemButton key={index} className='content_list' onClick={() => {}}>
                                                            <ListItemText primary={ele.title} />
                                                            <ModeEditOutlinedIcon className='edit_icon' onClick={(e) => {editKeyword(e, ele, index)}}/>&nbsp;&nbsp;
                                                        </ListItemButton>
                                            })
                                        }
                                    </List>
                                </Box>
                            </div>
                            
                    </CustomGrid>
                    
                </CustomGrid>
                
            </CustomWrapperInit>
        </>                                                             
    );
};

const CustomTextField = styled(TextField)`
    &.number_topic {
        margin-top: 10px!important;
    }
`

const CustomGrid = styled(Grid)`
    &#mainGrid {
        margin-top: 10px;
        margin-bottom: 5px;
        min-height: 80vh;
    }
    &.flex_direction_column {
        flex-direction: column!important;
    }

    &.site_label {
        display: flex!important;
        flex-direction: column!important;
        justify-content: center!important;
        padding-bottom: 8px!important;
    }

    &.content_grid {
        display: flex!important;
        justify-content: flex-start!important;

        .top_div {
            // background-color: #dff0f74d;
            // height: 690px;
            position: relative;
            display: flex;
            -webkit-box-align: center;
            // align-items: center;
            .top_div_main {
                padding-left: 24px;
                padding-right: 24px;
                width: 100%;
                margin-left: auto;
                box-sizing: border-box;
                margin-right: auto;
                display: block;
                .top_div_top {
                    // color: #05426c;
                    text-align: center;
                    font-family: Roboto,sans-serif;
                    font-weight: 400;
                    line-height: 1.2;
                    letter-spacing: 6px;
                    font-size: 1.25rem;
                    padding-top: 20px;
                }
                .top_div_bottom {
                    line-height: 32px;
                    font-size: 2.75rem;
                    font-family: Oswald,sans-serif;
                    font-weight: 700;
                    line-height: 1.1;
                    letter-spacing: 0.01em;
                    text-align: center;
                    max-width: 728px;
                    padding: 10px 0 25px;
                    margin: 0 auto;
                    box-sizing: inherit;
                }
            }
        }
        .middle_div {
            position: relative;
            display: flex;
            flex-direction: column!important;
            justify-content: center;
            width: 100%;
        }
        .bottom_div {
            // position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 10px!important;
            .main_paper{
                width: 95%!important;
                max-height: 80vh!important;
                overflow: auto!important;
                box-sizing: border-box!important;
            }
            .content_list{
                padding-left: 15px!important;
                border-bottom: 1px solid gray;
                .edit_icon:hover {
                    color: #0b9bef;
                }
                .delete_icon:hover {
                    color: #ef1634;
                }
            }
            // .content_list:hover {
            //     background-color: #a885e6!important;
            //     color: white!important;
            //     cursor: pointer!important;
            // }
        }
    }
`

const CustomWrapperInit = styled.div`
    // min-height: 100vh;
    @media (max-width: ${screenSizes.mediaM}px) {
        // display: none;
    }
    
`
export default Wix_Published