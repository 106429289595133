import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { edit_keyword } from 'modules/api/market-api'

export default function Dialog_edit(props:any) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [ keyword, setKeyword ] = React.useState<string>('');
  
  const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
        setOpen(props.open)
        setKeyword(props.keyword)
    }, [props.open])

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false)
  };

  const handleSave = () => {
    setOpen(false);
    props.setOpen(false)
    edit_keyword(props.keyword.replaceAll(' ', '-'), keyword.replaceAll(' ', '-'))
      .then(res => {
        // console.log(res.data.data)
        props.setKeyword_array(res.data.data)
      })
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Edit Keyword</DialogTitle>
        <DialogContent>
          <DialogContentText>
           
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            // id="name"
            label=""
            // type="email"
            fullWidth
            variant="standard"
            value={keyword}
            onChange={handleKeywordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}