import { SET_CURRENT_WIX_VALUE, SET_CURRENT_WIX_TOPIC_ARRAY, SET_CURRENT_WIX_IMAGE_ARRAY } from '../action/action.config'

const initialState = {
    currentKeyword: "",    
    currentNumberOfTopic: "1",    
    currentGenerator: "openai",    
    currentTopicArray: [],    
    currentImgArray: [],    
}

export const wixReducer = (state = initialState, action: any) => {
    const { type, payload } = action
    switch (type) {
        case SET_CURRENT_WIX_VALUE:
            return {
                ...state,
                currentKeyword: payload.keyword,
                currentNumberOfTopic: payload.number_of_topic,
                currentGenerator: payload.generator,
            }
        case SET_CURRENT_WIX_TOPIC_ARRAY:
            return {
                ...state,
                currentTopicArray: payload.topic_array,
            }
        case SET_CURRENT_WIX_IMAGE_ARRAY:
            return {
                ...state,
                currentImgArray: payload.img_array,
            }
        default:
            return state
    }
}
