import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from "react-router-dom";
import styled from 'styled-components'
import { Grid, Paper, IconButton, InputBase, Divider, Menu, MenuItem, Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { screenSizes } from 'shared/styles/theme'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { setCurrentProductUrl } from 'logic/action/base.action'
import history from 'modules/app/components/history'
import jwt_decode from 'jwt-decode'

const ITEM_HEIGHT = 48;

const Header = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const [ searchValue, setSearchValue ] = useState('')
  
  const [ anchorEl, setAnchorEl ] = useState<any>(null)
  const [ title, setTitle ] = useState<string>('')

  const handleClose = () => {
      setAnchorEl(null);
  };

  const handleClick = (index: number, event: React.MouseEvent<HTMLElement>, btnText: string) => {
      setTitle(btnText)
      setAnchorEl({ [index]: event.currentTarget });
  };

  const changeProduct = (product: any) => {
      setAnchorEl(null);
      // setCategory(category)
      // dispatch(setCurrentProductName(category))
      let product_url = product.toLowerCase().split(' ').join('-')
      // console.log(product_url)
      history.push(`/${product_url}`)
  }

  const goStartPage = () => {
      history.push(`/`)
  } 

  const SignIn = () => {
    history.push(`/signin`)
  }

  const SignOut = () => {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('userDbId')
    document.querySelector('out-elem')?.shadowRoot?.querySelector('button')?.click()
    history.push(`/`)
  } 

  const renderSignButton = () => {
    if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
      const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
      if ( decoded.id == localStorage.getItem('userDbId') ) {
        return <Button variant="contained" className="non_upper" onClick={SignOut}>Sign Out</Button>
      } else {
        return <Button variant="contained" className="non_upper" onClick={SignIn}>Sign In</Button>
      }
    } else {
        return <Button variant="contained" className="non_upper" onClick={SignIn}>Sign In</Button>
    }
  }

  const { pathname } = useLocation();
  // console.log(pathname);
  if (pathname === "/signin") return null;

  return (
    <HeaderContainer>
      <CustomGrid container spacing={0} sx={{paddingTop: '10px'}}>
        <CustomGrid item xs={4} className="grid-header-left header_logo" onClick={goStartPage} >
          {/* <img src={require('assets/logo_avatar3.png').default} style={{width: '52px!important'}} alt="" />
          &nbsp;<span><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</span> */}
        </CustomGrid>
        <CustomGrid item xs={8} className="grid-flex-item-end grid-header-right" >
          {
            renderSignButton()
          }
        </CustomGrid>
      </CustomGrid>
      <Divider />
    </HeaderContainer>

  )
}

const HeaderContainer = styled.div`
  @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`
const CustomGrid = styled(Grid)`
  background-color: white!important;
  padding: 8px 20px;
  margin: 0px!important;

  .header_logo{
    display: flex;
    align-items: center;
    font-size: 25px;
    cursor: pointer;
  }

  img {
    // width: 192px;
    // height: 48px;
    width: 45px;
    height: 45px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
    .non_upper {
      text-transform: none!important;
    }
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-left: 185px!important;
    }
  }

  .grid-header-right {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
`

export default withRouter(Header)
