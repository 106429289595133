import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { update_question_answer } from 'modules/api/market-api'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dialog_delete(props:any) {
  const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.open])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false)
  };
  const handleDelete = () => {
    const arr = [...props.questions]
    arr.splice(props.index, 1)
    
    update_question_answer(props.keyword, arr)
      .then(result => {
        props.setQuestions(result.data.data.qas)
      })

    setOpen(false);
    props.setOpen(false)
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Question and Answer"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {
            props.questions.length != 0 ? '\'' + props.questions[props.index].question + '\' will be deleted, are you okay?' : ''
          }
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>OK</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}