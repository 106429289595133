import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
// import MobileDiv from './MobileDiv'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import VideosList from './components/VideosList'
import Users from './components/Users'
import { get_video_list_from_db } from 'modules/api/market-api'
import history from 'modules/app/components/history'
import jwt_decode from 'jwt-decode'


const Videos = (props: any) => {
    const [ isLoading, setIsLoading ] = React.useState<boolean>(false)
    const [value, setValue] = React.useState('1')

    const [ records_page, setRecordsPage ] = React.useState(0)
    const [ records_rows_per_page, setRecordsRowsPerPage ] = React.useState(10)
    const [ records_count, setRecordsCount ] = React.useState(0)
    const [ rows_records, setRowsRecords ] = React.useState([
        {
            link: '',
            videos_for_this_product: '',
            videos_for_related_products: '',
        },
    ])

    // const [ users_page, setUsersPage ] = React.useState(0)
    // const [ users_rows_per_page, setUsersRowsPerPage ] = React.useState(10)
    // const [ users_count, setUsersCount ] = React.useState(0)
    // const [ rows_users, setRowsUsers ] = React.useState([
    //     {
    //         no: 0,
    //         username: 'no',
    //         gclid: 'no',
    //         msclkid: 'no',
    //         amazon_tracking_id: 'no',
    //         time_stamp: ''
    //     },
    // ])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }

    useEffect(() => {
        if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
            const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
            if ( decoded.id == localStorage.getItem('userDbId') ) {
                setIsLoading(true)
                get_video_list_from_db(records_page, records_rows_per_page)
                    .then((result:any) => {
                        if ( result.status === 'success' ) {
                            setIsLoading(false)
                            setRowsRecords(result.data.extract_videos)
                            setRecordsCount(result.data.count)
                        } else if ( result.status === 'error' ) {
                            setIsLoading(false)
                            history.push('/signin')
                        }
                        
                    })
            } else {
                history.push(`/signin`)
            }
        } else {
            history.push(`/signin`)
        }
    }, [records_page, records_rows_per_page])

    // useEffect(() => {
    //     if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
    //         const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
    //         if ( decoded.id == localStorage.getItem('userDbId') ) {
    //             setIsLoading(true)
    //             getUsers(users_page, users_rows_per_page)
    //                 .then((result:any) => {
    //                     if ( result.status === 'success' ) {
    //                         setIsLoading(false)
    //                         setUsersCount(result.data.count)
    //                         setRowsUsers(result.data.users)
    //                     } else if ( result.status === 'error' ) {
    //                         setIsLoading(false)
    //                         history.push('/admin/signin')
    //                     }
                        
    //                 })
    //         } else {
    //             history.push(`/admin/signin`)
    //         }
    //     } else {
    //         history.push(`/admin/signin`)
    //     }
    // }, [users_page, users_rows_per_page])

    return (
        <>
            <CustomWrapperTable>
                {
                    isLoading === true ? 
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit"/>
                        <p>&nbsp;&nbsp; Loading ...</p>
                    </Backdrop> : <></>
                }
                <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '0px!important'}}>
                    <CustomGrid item container spacing={0} sm={12} className="grid_main" sx={{background: 'transparent!important'}} >
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <VideosList 
                                page={records_page} 
                                setPage={setRecordsPage} 
                                rowsPerPage={records_rows_per_page} 
                                setRowsPerPage={setRecordsRowsPerPage} 
                                rows={rows_records} 
                                count={records_count} 
                            />
                            {/* <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Records" value="1" />
                                        <Tab label="Users" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1"><Records page={records_page} setPage={setRecordsPage} rowsPerPage={records_rows_per_page} setRowsPerPage={setRecordsRowsPerPage} rows={rows_records} count={records_count} /></TabPanel>
                                <TabPanel value="2"><Users page={users_page} setPage={setUsersPage} rowsPerPage={users_rows_per_page} setRowsPerPage={setUsersRowsPerPage} rows={rows_users} count={users_count} /></TabPanel>
                            </TabContext> */}
                        </Box>
                    </CustomGrid>
                </CustomGrid>
            </CustomWrapperTable>

            {/* <MobileDiv /> */}
            {/* <MobileDiv value={value} setValue={setValue} rows_records={rows_records} rows_users={rows_users} /> */}
        </>                                                               
    );
};

const CustomWrapperTable = styled.div`
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (max-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`
const CustomGrid = styled(Grid)`
  background-color: white!important;

  padding: 8px 20px;
  margin: 0px!important;

  .grid_main{
    display: flex;
    justify-content: start;
  }

`
export default Videos