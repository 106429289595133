import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Grid, Paper, List } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import history from 'modules/app/components/history'
import { get_topic, get_topic_description } from 'modules/api/market-api'
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import jwt_decode from 'jwt-decode'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import Dialog_delete from './Dialog_delete'
import Dialog_edit from './Dialog_edit'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { setCurrentDraft, setCurrentWixValue, setCurrentWixTopicArray, setCurrentImgArray } from 'logic/action/base.action'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Wix from './Wix'
import Wix_Published from './Wix_Published'
import Wix_Scheduled from './Wix_Scheduled'

const Wix01 = (props: any) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: any) => state)

    const [ keyword, setKeyword ] = useState<string>("")
    const [ number_of_topic, setNumberTopic ] = useState<string>("1")
    const [ generator, setGenerator] = React.useState('openai');

    const [ topic_array, setTopic_array ] = useState<any>([])

    const [ topic, setTopic ] = useState<string>("")
    const [ topic_title, setTopicTitile ] = useState<string>("")
    const [ topic_description, setTopicDescription ] = useState<string>("")
   
    const [ editDialogOpen, setEditDialogOpen ] = useState<boolean>(false)
    const [ editIndex, setEditIndex ] = useState<number>(0)

    const [ isLoading, setIsLoading ] = useState<boolean>(false)

    const [value, setValue] = React.useState('2');

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };
    
    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGenerator((event.target as HTMLInputElement).value);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }
    const handleNumberTopic = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ( e.target.value == '' || parseInt(e.target.value) < 1) {
            setNumberTopic("1")
        } else if (parseInt(e.target.value) > 20) {
            setNumberTopic("20")
        } else {
            setNumberTopic(e.target.value)
        }        
    }
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ( e.key == 'Enter' && keyword != "") {
            dispatch(setCurrentWixValue(keyword, number_of_topic, generator))

            setIsLoading(true)
            get_topic(keyword, parseInt(number_of_topic), generator)
                .then(result => {
                    // console.log(result.data)
                    // console.log(result.data.topic_array)
                    // console.log(result.data.img_array)
                    setTopic(keyword)
                    // setTopic_array(result.data.topic_array)
                    dispatch(setCurrentWixTopicArray(result.data.topic_array))
                    dispatch(setCurrentImgArray(result.data.img_array))
                    setIsLoading(false)
                })
                .catch(error => {
                    setIsLoading(false)
                    // console.log(error)
                })
        }
    }

    const gotoQaPage = (link: string) => {
        history.push(`/qa_manager/${link}`)
    }

    const editKeyword = (e:any, topicTitle: string, index: number) => {
        // e.stopPropagation()
        // console.log(topicTitle)
        setIsLoading(true)
        setTopicTitile(topicTitle)
        get_topic_description(topicTitle)
            .then(result => {
                let topicDescription = result.data.topic_description
                // console.log(topicDescription)
                setTopicDescription(topicDescription)

                setIsLoading(false)
                // setEditIndex(index)
                // setEditDialogOpen(true)

                dispatch(setCurrentDraft(topicTitle, topicDescription, index))
                history.push(`/wix/draft`)
            })
            .catch(error => {
                setIsLoading(false)
                // console.log(error)
            })
    }

    useEffect(() => {
        setKeyword(selector.wix.currentKeyword)
        setNumberTopic(selector.wix.currentNumberOfTopic)
        setGenerator(selector.wix.currentGenerator)

        // console.log(selector.wix)
    },[selector.wix.currentKeyword, selector.wix.currentNumberOfTopic, selector.wix.currentGenerator])

    useEffect(() => {
        setTopic_array(selector.wix.currentTopicArray)
        
        // console.log(selector.wix.currentTopicArray)
    },[selector.wix.currentTopicArray])

    return (
        // isLoading == true ? 
        // <> 
        //     <Backdrop
        //         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //         open={isLoading}
        //         // onClick={handleClose}
        //     >
        //         <CircularProgress color="inherit"/>
        //         <p>&nbsp;&nbsp; Loading Data ...</p>
        //     </Backdrop>
        //     <CustomWrapperInit>
        //         <CustomGrid id="mainGrid" container spacing={0}>
        //             <CustomGrid item xs={12} sm={2}>

        //             </CustomGrid>
        //             <CustomGrid item xs={12} sm={8} className="content_grid flex_direction_column">
        //                     <div className='middle_div'>
        //                         <TextField 
        //                             variant="outlined" 
        //                             label='Please input the keyword' 
        //                             sx={{width: '100%'}}
        //                             value={keyword}
        //                             onChange={handleChange}
        //                             onKeyDown={handleKeyDown}
        //                         />
        //                         <CustomTextField
        //                             className="number_topic"
        //                             id="outlined-number"
        //                             label="Number of topic"
        //                             sx={{width: '100%'}}
        //                             type="number"
        //                             InputLabelProps={{
        //                                 shrink: true,
        //                             }}
        //                             InputProps={{ inputProps: { min: 1, max: 20 } }}
        //                             value={number_of_topic}
        //                             onChange={handleNumberTopic}
        //                             onKeyDown={handleKeyDown}
        //                         />
        //                          <FormControl>
        //                             <RadioGroup
        //                                 row
        //                                 aria-labelledby="demo-controlled-radio-buttons-group"
        //                                 name="controlled-radio-buttons-group"
        //                                 value={generator}
        //                                 onChange={handleChangeRadio}
        //                             >
        //                                 <FormControlLabel value="openai" control={<Radio />} label="Openai" sx={{marginRight: '60px'}} />
        //                                 <FormControlLabel value="spyfu" control={<Radio />} label="Spyfu" />
        //                             </RadioGroup>
        //                         </FormControl>
        //                     </div>
        //                     <div className='bottom_div'>
        //                         <Box className="main_paper">
        //                             <List component="nav" aria-label="secondary mailbox folder">
        //                                 {
        //                                     topic_array.map((ele: any, index: number) => {
        //                                         return <ListItemButton key={index} className='content_list' onClick={() => {}}>
        //                                                     <ListItemText primary={ele} />
        //                                                     <ModeEditOutlinedIcon className='edit_icon' onClick={(e) => {editKeyword(e, ele, index)}}/>&nbsp;&nbsp;
        //                                                 </ListItemButton>
        //                                     })
        //                                 }
        //                             </List>
        //                         </Box>
        //                     </div>
        //             </CustomGrid>
        //             <CustomGrid item xs={12} sm={2}>

        //             </CustomGrid>
        //         </CustomGrid>
                
        //     </CustomWrapperInit>
        // </> :
        <>
            <CustomWrapperInit>
                <CustomGrid id="mainGrid" container spacing={0}>
                    <CustomGrid item xs={12} sm={2}>

                    </CustomGrid>
                    <CustomGrid item xs={12} sm={8} className="content_grid flex_direction_column">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                    <CustomTab label="New Article" value="1" className='lowcasetab' />
                                    <CustomTab label="Published Articles" value="2" className='lowcasetab' />
                                    <CustomTab label="Scheduled Articles" value="3" className='lowcasetab' />
                                </TabList>
                                </Box>
                                {/* <TabPanel value="1">Item One</TabPanel> */}
                                <TabPanel value="1"><Wix /></TabPanel>
                                <TabPanel value="2"><Wix_Published /></TabPanel>
                                <TabPanel value="3"><Wix_Scheduled /></TabPanel>
                            </TabContext>
                        </Box>
                    </CustomGrid>
                    <CustomGrid item xs={12} sm={2}>

                    </CustomGrid>
                </CustomGrid>
                
            </CustomWrapperInit>
            {/* <Dialog_edit open={editDialogOpen} setOpen={setEditDialogOpen} topic_title={topic_title} topic_description={topic_description} index={editIndex} setIsLoading={setIsLoading} questions={topic_array} setQuestions={setTopic_array} keyword={topic} /> */}
        </>                                                             
    );
};

const CustomTab = styled(Tab)`
    &.lowcasetab {
        text-transform: none!important;
    } 
`

const CustomTextField = styled(TextField)`
    &.number_topic {
        margin-top: 10px!important;
    }
`

const CustomGrid = styled(Grid)`
    &#mainGrid {
        margin-top: 10px;
        margin-bottom: 5px;
        min-height: 80vh;
    }
    &.flex_direction_column {
        flex-direction: column!important;
    }

    &.content_grid {
        display: flex!important;
        justify-content: flex-start!important;

        .top_div {
            // background-color: #dff0f74d;
            // height: 690px;
            position: relative;
            display: flex;
            -webkit-box-align: center;
            // align-items: center;
            .top_div_main {
                padding-left: 24px;
                padding-right: 24px;
                width: 100%;
                margin-left: auto;
                box-sizing: border-box;
                margin-right: auto;
                display: block;
                .top_div_top {
                    // color: #05426c;
                    text-align: center;
                    font-family: Roboto,sans-serif;
                    font-weight: 400;
                    line-height: 1.2;
                    letter-spacing: 6px;
                    font-size: 1.25rem;
                    padding-top: 20px;
                }
                .top_div_bottom {
                    line-height: 32px;
                    font-size: 2.75rem;
                    font-family: Oswald,sans-serif;
                    font-weight: 700;
                    line-height: 1.1;
                    letter-spacing: 0.01em;
                    text-align: center;
                    max-width: 728px;
                    padding: 10px 0 25px;
                    margin: 0 auto;
                    box-sizing: inherit;
                }
            }
        }
        .middle_div {
            position: relative;
            display: flex;
            flex-direction: column!important;
            justify-content: center;
            width: 100%;
        }
        .bottom_div {
            // position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 10px!important;
            .main_paper{
                width: 95%!important;
                max-height: 80vh!important;
                overflow: auto!important;
                box-sizing: border-box!important;
            }
            .content_list{
                padding-left: 15px!important;
                border-bottom: 1px solid gray;
                .edit_icon:hover {
                    color: #0b9bef;
                }
                .delete_icon:hover {
                    color: #ef1634;
                }
            }
            // .content_list:hover {
            //     background-color: #a885e6!important;
            //     color: white!important;
            //     cursor: pointer!important;
            // }
        }
    }
`

const CustomWrapperInit = styled.div`
    // min-height: 100vh;
    @media (max-width: ${screenSizes.mediaM}px) {
        // display: none;
    }
    
`
export default Wix01