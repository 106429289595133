import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from "react-router-dom";
import { withRouter } from 'react-router'
import { FlexCont } from 'shared/styles/styled'
import { MobileHeaderCont, MobileHeaderTitleCont, CustomSpan, BoldSpan } from './style'
import { MobileSideNav } from '../side-nav'
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import history from 'modules/app/components/history'

const MobileHeader = (props: any) => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  
  const [showSideNav, setShowSideNav] = useState(false)

  const goStartPage = () => {
      history.push(`/`)
  } 

  const { pathname } = useLocation();
  // console.log(pathname);
  if (pathname === "/signin") return null;

  return (
    <Fragment>
      <MobileHeaderCont>
        <FlexCont>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-haspopup="true"
              onClick={() => setShowSideNav(true)}
              style={{ padding: 0, marginRight: '10px'}}
            >
              <MenuIcon style={{ fontSize: '28px', color: 'black'}} />
            </IconButton>
            <MobileHeaderTitleCont onClick={goStartPage}>
              {/* <img src={require('assets/logo_avatar3.png').default} alt="" />
              &nbsp;<CustomSpan ><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</CustomSpan> */}
            </MobileHeaderTitleCont>
        </FlexCont>
        
      </MobileHeaderCont>
      <MobileSideNav showSideNav={showSideNav} setShowSideNav={setShowSideNav} />
     
    </Fragment>
  )
}

export default withRouter(MobileHeader)
