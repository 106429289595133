import { SET_CURRENT_DRAFT_UPDATE } from '../action/action.config'

const initialState = {
    // currentSite: "",    
    currentSite: "",
    currentBlogId: "",
    currentArticleId: "",
    currentUpdateTitle: "",    
    currentUpdateDescription: "",   
    currentUpdateImage: "",
}

export const drafUpdatetReducer = (state = initialState, action: any) => {
    const { type, payload } = action
    switch (type) {
        case SET_CURRENT_DRAFT_UPDATE:
            return {
                ...state,
                // currentSite: payload.site,
                currentSite: payload.site,
                currentBlogId: payload.blog_id,
                currentArticleId: payload.article_id,
                currentUpdateTitle: payload.title,
                currentUpdateDescription: payload.description,
                currentUpdateImage: payload.image
            }
        default:
            return state
    }
}
