export enum Paths {
  // input = '/input',
  root = '/',
  qa = '/qa/:keyword',
  qa_manager = '/qa_manager/:keyword',
  input = '/',
  manager = '/manager',
  signin = '/signin',
  wix = '/wix',
  draft = '/wix/draft',
  draft_update = '/wix/update',
  ai_voice = '/aivoice',
  campaign = '/campaign',
  videos = '/videos',
  videos_search = '/videos_search',
}