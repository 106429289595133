import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { edit_keyword, get_questions_answers } from 'modules/api/market-api'
import { update_question_answer } from 'modules/api/market-api'

export default function Dialog_edit(props:any) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [ question, setQuestion ] = React.useState<string>('')
  const [ answer, setAnswer ] = React.useState<string>('')

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value)
  }
  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(e.target.value)
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
        setOpen(props.open)
        if(props.questions.length != 0) {
          setQuestion(props.questions[props.index].question)
          setAnswer(props.questions[props.index].answer)
        }
    }, [props.open])

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false)
  };

  const handleSave = () => {
    const arr = [...props.questions]
    arr[props.index].question = question
    arr[props.index].answer = answer

    update_question_answer(props.keyword, arr)
      .then(result => {
        props.setQuestions(result.data.data.qas)
      })

    setOpen(false);
    props.setOpen(false)
    
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Edit Question and Answer</DialogTitle>
        <DialogContent>
          <DialogContentText>
           question:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            // id="name"
            // label="question"
            // type="email"
            fullWidth
            // variant="standard"
            value={question}
            onChange={handleQuestionChange}
          />
          <DialogContentText margin="dense">
           answer:
          </DialogContentText>
          <TextField
            margin="dense"
            // label="answer"
            fullWidth
            multiline
            rows={8}
            value={answer}
            onChange={handleAnswerChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}