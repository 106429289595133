import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch } from 'react-router'
import { Router, Route } from 'react-router-dom'
import history from '../history'
import { Paths } from './types'
import { NotFound } from './NotFound'
import { RouteContainer } from './style'
import Input from 'modules/input'
import Manager from 'modules/manager'
import Ai_voice from 'modules/ai_voice'
import Campaign from 'modules/campaign'
import Qa_manager from 'modules/manager/qa_manager'
import Qa from 'modules/qa'
import Signin from 'modules/signin'
// import Wix from 'modules/wix01'
import Wix from 'modules/wix'
import Wix01 from 'modules/wix'
import Videos from 'modules/videos'
import Videos_Search from 'modules/videos_search'
import Draft from 'modules/wix/draft'
import Draft_Update from 'modules/wix/draft_update'
import Footer from 'modules/app/components/footer'
import { Header, MobileHeader } from 'modules/app/components/header'

const notFoundRoute: RouteDefinition = {
  path: '*',
  component: NotFound,
  protected: false,
  title: '',
}

export const routes: RouteDefinition[] = [
  {
    path: Paths.qa,
    component: Qa,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.input,
    component: Input,
    protected: false,
    redirect: Paths.root
  },
  {
    path: Paths.manager,
    component: Manager,
    protected: false,
    redirect: Paths.root
  },
  {
    path: Paths.qa_manager,
    component: Qa_manager,
    protected: false,
    redirect: Paths.root
  },
  {
    path: Paths.signin,
    component: Signin,
    protected: false,
    redirect: Paths.root
  },
  {
    path: Paths.wix,
    // component: Wix,
    component: Wix01,
    protected: false,
    redirect: Paths.root
  },
  {
    path: Paths.draft,
    component: Draft,
    protected: false,
    redirect: Paths.root
  },
  {
    path: Paths.draft_update,
    component: Draft_Update,
    protected: false,
    redirect: Paths.root
  },
  {
    path: Paths.ai_voice,
    component: Ai_voice,
    protected: false,
    redirect: Paths.root
  },
  {
    path: Paths.campaign,
    component: Campaign,
    protected: false,
    redirect: Paths.root
  },
  {
    path: Paths.videos,
    component: Videos,
    protected: false,
    redirect: Paths.root
  },
  {
    path: Paths.videos_search,
    component: Videos_Search,
    protected: false,
    redirect: Paths.root
  }
].concat(notFoundRoute as any) // Ensure that notFound is the last route

export interface RouteDefinition {
  path: string
  protected?: boolean
  redirect?: string
  component?: any
  routes?: RouteDefinition[]
  title?: string
  pathType?: number
}

interface Props { }
interface RoutesProps { }

function getRouteRenderWithAuth(route: RouteDefinition, i: number) {
  return () => <route.component />
}

const Routes: React.FC<Props & RoutesProps> = () => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()
  // const { authLoading } = selector.user

  return (
    <Router history={history}>
      <RouteContainer>
        <Header />
        <MobileHeader />
        <Switch>
          {routes.length > 0 && routes.map((route, i) => {
              const render = getRouteRenderWithAuth(route, i)
              const rest = { render }
              return <Route key={i} path={route.path} exact {...rest} />
          })}
        </Switch>
        <Footer />
      </RouteContainer>
    </Router>
  )
}

export default Routes
