import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { get_questions_answers } from 'modules/api/market-api'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Qa = (props: any) => {

    const { keyword } = useParams<{ keyword: string }>();
    const [ questions, setQuestions ] = useState<any>([])
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    // const [ isLoading, setIsLoading ] = useState<boolean>(false)

    useEffect(() => {
        get_questions_answers(keyword)
            .then(result => {
                // console.log(result)
                // console.log(result.data.data)

                let qa_array = result.data.data
                let mainEntity: any = [];
                qa_array.map((el: any) => {
                    mainEntity.push({
                        "@type": "Question",
                        "name": el.question,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": el.answer
                        }
                    })
                })
                const structuredData = {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": mainEntity
                }        
                const script = document.createElement('script');
                script.setAttribute('type', 'application/ld+json');
                script.textContent = JSON.stringify(structuredData);
                document.head.appendChild(script);
        
                setQuestions(result.data.data)
                setIsLoading(false)
            })

    }, [keyword])

    return (
        isLoading == true ? 
        <> 
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
                <p>&nbsp;&nbsp; Loading Data ...</p>
            </Backdrop>
            <CustomWrapperInit>
                <CustomGrid id="mainGrid" container spacing={0}>
                    <CustomGrid item sm={12} md={2}>

                    </CustomGrid>
                    <CustomGrid item sm={12} md={8}>
                        <div className='top_div'>
                            <div className='top_div_main'>
                                <div className='top_div_top'>FAQ</div>
                                <div className='top_div_bottom'>Frequently Asked Questions</div>
                            </div>
                        </div>
                        <div className='bottom_div'>
                            
                        </div>
                    </CustomGrid>
                    <CustomGrid item sm={12} md={2}>

                    </CustomGrid>
                </CustomGrid>
            </CustomWrapperInit>
        </> :
        <>
            <CustomWrapperInit>
                <CustomGrid id="mainGrid" container spacing={0}>
                    <CustomGrid item sm={12} md={2}>

                    </CustomGrid>
                    <CustomGrid item sm={12} md={8}>
                        <div className='top_div'>
                            <div className='top_div_main'>
                                <div className='top_div_top'>FAQ</div>
                                <div className='top_div_bottom'>Frequently Asked Questions</div>
                            </div>
                        </div>
                        <div className='bottom_div'>
                            <CustomGrid item container spacing={0} xs={12} className="center_grid">
                                
                                <CustomGrid item container spacing={0} xs={12} className="text_body_grid">
                                {   questions.length !== 0 ?
                                    questions.length > 0 && questions.map((item: any, index: number)=> {
                                            // console.log(index, item.ItemInfo.ByLineInfo.Brand)
                                        return (
                                            <CustomAccordion key={index}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#d0af87' }}/>} aria-controls="acc1" id="acc1">
                                                    <CustomTypography>{item.question}</CustomTypography>
                                                </AccordionSummary>
                                                <AccordionDetails className="italic_style">
                                                    {item.answer.split('\n').map((ele:any, index:number) => {
                                                        return <p key={index}>{ele}</p>
                                                    })}
                                                </AccordionDetails>
                                            </CustomAccordion>
                                            )
                                    }
                                    ) : ''
                                }
                                    {/* <CustomAccordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#d0af87' }}/>} aria-controls="acc1" id="acc1">
                                            <CustomTypography>How many watts does the Vitamix 5200 have?</CustomTypography>
                                        </AccordionSummary>
                                        <AccordionDetails className="italic_style">
                                            <p>The Vitamix 5200 has<br/> a motor with a peak output of 2 peak output horsepower or 1491 watts</p>
                                        </AccordionDetails>
                                    </CustomAccordion> */}
                                    
                                </CustomGrid>
                            </CustomGrid>
                        </div>
                    </CustomGrid>
                    <CustomGrid item sm={12} md={2}>

                    </CustomGrid>
                </CustomGrid>
            </CustomWrapperInit>
        </>                                                                     
    );
};

const CustomAccordion = styled(Accordion)`
    width: 100%!important;
    .italic_style {
        font-style: italic;
    }
`
const CustomTypography = styled(Typography)`
    font-size: 16px!important;
    font-weight: 600!important;
`
const Toptitle = styled.span`
    font-size: 32px;
    font-weight: 700;
    margin: 10px 0;
`

const CustomGrid = styled(Grid)`
    &#mainGrid {
        margin-bottom: 5px;
        min-height: 85vh;
    }
    &.text_body_grid {
        padding: 0 10px!important;
    }
`

const CustomWrapperInit = styled.div`
    // min-height: 100vh;
    @media (max-width: ${screenSizes.mediaM}px) {
        // display: none;
    }
    .top_div {
        // background-color: #dff0f74d;
        // height: 690px;
        position: relative;
        display: flex;
        -webkit-box-align: center;
        // align-items: center;
        .top_div_main {
            padding-left: 24px;
            padding-right: 24px;
            width: 100%;
            margin-left: auto;
            box-sizing: border-box;
            margin-right: auto;
            display: block;
            .top_div_top {
                // color: #05426c;
                text-align: center;
                font-family: Roboto,sans-serif;
                font-weight: 400;
                line-height: 1.2;
                letter-spacing: 6px;
                font-size: 1.25rem;
                padding-top: 20px;
            }
            .top_div_bottom {
                line-height: 32px;
                font-size: 2.75rem;
                font-family: Oswald,sans-serif;
                font-weight: 700;
                line-height: 1.1;
                letter-spacing: 0.01em;
                text-align: center;
                max-width: 728px;
                padding: 10px 0 25px;
                margin: 0 auto;
                box-sizing: inherit;
            }
        }
    }
    .bottom_div {
        position: relative;
        display: flex;
        // background-color: rgb(187, 102, 26);
        // height: 244px;
        // padding-left: 24px;
        // padding-right: 24px;

        .css-1iji0d4:hover{
            background-color: #a885e6!important;
            color: white!important;
          }
          
        .css-1iji0d4.Mui-expanded {
        background-color: #a885e6!important;
        color: white!important;
        }
        .css-67l5gl:before{
        height: 0px!important;
        }
        .css-67l5gl {
        box-shadow: none!important;
        border-bottom: 2px solid #a885e6!important;
        margin-top: 1px!important;
        }
        .css-67l5gl.Mui-expanded {
        margin: 2px 0px!important;
        }
    }
`
export default Qa