import { formControlUnstyledClasses } from '@mui/base';
import axios from 'axios'

// axios.defaults.baseURL = 'http://localhost:5001/api/v1/'
// axios.defaults.baseURL = 'http://ec2-3-94-243-115.compute-1.amazonaws.com:3000/api/v1/';
// axios.defaults.baseURL = 'https://flashanswers.com:3000/api/v1/';
axios.defaults.baseURL = 'https://flashanswers.com/api/v1/';


/////////////////////////////////////////////////////////////// User Api /////////////////////////////////////////////////////////////

export async function login(userData: any) {
  return await axios 
    .post('/users/login', userData)
    .then((res) => {
      return {
        status: 'success',
        result: res.data
      }
    })
    .catch(err => {
      return {
        status: 'fail',
        result: err.response.data
      }
    })
}

/////////////////////////////////////////////////////////////// Base Api /////////////////////////////////////////////////////////////

export async function get_questions_answers(keyword: string) {
  // console.log(keyword)
  return await axios
    .post('/openai/get_questions_answers', 
      {
        keyword,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      // console.log(res)
      return res
    })
}

export async function get_all_keywords() {
  return await axios
    .post('/openai/get_all_keywords', 
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function get_questions_answers_by_manager(keyword: string) {
  // console.log(keyword) //
  return await axios
    .post('/openai/get_questions_answers_by_manager', 
      {
        keyword,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      // console.log(res)
      return res
    })
}

export async function delete_keyword(keyword: string) {
  // console.log(keyword)
  return await axios
    .post('/openai/delete_keyword',
      {keyword},
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function edit_keyword(oldKeyword: string, newKeyword: string) {
  return await axios
    .post('/openai/edit_keyword',
      {
        oldKeyword,
        newKeyword,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function update_question_answer(keyword: string, qas: any) {
  // console.log(keyword, qas)
  return await axios
    .post('/openai/update_question_answer',
      {
        keyword,
        qas,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}


///////////////////////////////////////////////////////////////////////////// wix //////////////////////////////////////////////////////////////////

export async function get_topic(keyword: string, number: number, generator: string) {
  // console.log('get_topic')
  return await axios
    .post('/wix/get_topic', 
      {
        keyword,
        number,
        generator
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function get_topic_description(title: string) {
  // console.log('get_topic_description')
  return await axios
    .post('/wix/get_topic_description', 
      {
        title,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function publish_post(title: string, description: string, site: string) {
  // console.log(keyword, qas)
  return await axios
    .post('/wix/publish_post_flow',
      {
        title,
        description,
        site,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function publish_post02(title: string, description: string, site: string, img: string) {
  // console.log(keyword, qas)
  return await axios
    .post('/wix/publish_post_flow02',
      {
        title,
        description,
        site,
        img,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function get_all_articles(site: string) {
  // console.log(keyword, qas)
  return await axios
    .post('/wix/get_all_articles',
      {
        site,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function get_all_scheduled_articles(site: string) {
  // console.log(keyword, qas)
  return await axios
    .post('/wix/get_all_scheduled_articles',
      {
        site,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function update_article(site: string, article_id: string, title: string, description: string, image: string) {
  // console.log(keyword, qas)
  return await axios
    .post('/wix/update_article',
      {
        site,
        article_id,
        title,
        description,
        image,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function get_image_by_image_topic(imgTopic: string) {
  return await axios
    .post('/wix/get_image_by_image_topic',
      {
        imgTopic,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function schedule_article(title: string, description: string, site: string, img: string, schedule_date_time: string) {
  return await axios 
    .post('/wix/schedule_article',
      {
        title,
        description,
        site,
        img,
        schedule_date_time,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}

////////////////////////////////////////////////////////// ai voice ///////////////////////////////////////////////////////////////////

export async function generate_text_to_speech(input_text: string) {
  return await axios 
    .post('/aivoice/text_to_speech',
      {
        input_text,
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}


/////////////////////////////////////////////////////////// report campaign /////////////////////////////////////////////////////////////

export async function get_all_campaigns() {
  return await axios 
    .post('/aivoice/get_all_campaigns',
      {
        
      },
      {
        headers : {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"      
        }
      }
    )
    .then((res) => {
      return res
    })
}


export async function get_video_list_from_db(page:number, rows_per_page:number) {
  return await axios
    .post('/video/get_video_list_from_db', 
      {
        page,
        rows_per_page,
      }
    )
    .then((res) => {
      // return res.data
      return {
        status: 'success',
        data: res.data,
      }
    })
    .catch((err) => {
      console.log(err)
      return {
        status: 'error',
        error: err
      }
    })
}

export async function scrape_product_links_and_their_videos_from_one_link(input_link:string) {
  return await axios
    .post('/video/scrape_product_links_and_their_videos_from_one_link', 
      {
        input_link,
      }
    )
    .then((res) => {
      // return res.data
      return {
        status: 'success',
        data: res.data,
      }
    })
    .catch((err) => {
      console.log(err)
      return {
        status: 'error',
        error: err
      }
    })
}