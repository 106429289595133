import { SET_CURRENT_PRODUCT, SET_CURRENT_PRODUCT_ASIN, SET_CURRENT_GCLID_CURRENT_SEARCH, SET_CURRENT_DRAFT, SET_CURRENT_WIX_VALUE, SET_CURRENT_WIX_TOPIC_ARRAY, SET_CURRENT_WIX_IMAGE_ARRAY, SET_CURRENT_DRAFT_UPDATE } from './action.config'

export const setCurrentProductUrl = (productUrl: string | undefined) => {
    return {
        type: SET_CURRENT_PRODUCT,
        payload: { productUrl }
    }
}

export const setCurrentProductAsin = (asin: string | undefined) => {
    return {
        type: SET_CURRENT_PRODUCT_ASIN, 
        payload: { asin }
    }
}

export const setCurrentGclidAndCurrentSearch = (gclid: string | undefined, msclkid: string | undefined, search: string | undefined) => {
    return {
        type: SET_CURRENT_GCLID_CURRENT_SEARCH,
        payload: { gclid, msclkid, search }
    }
}
export const setCurrentDraftUpdate = (site: string | undefined, blog_id: string | undefined, article_id: string | undefined, title: string | undefined, description: string | undefined, image: string | undefined) => {
    return {
        type: SET_CURRENT_DRAFT_UPDATE,
        payload: { site, blog_id, article_id, title, description, image }
    }
}
export const setCurrentDraft = (title: string | undefined, description: string | undefined, index: number | undefined) => {
    return {
        type: SET_CURRENT_DRAFT,
        payload: { title, description, index }
    }
}
export const setCurrentWixValue = (keyword: string | undefined, number_of_topic: string | undefined, generator: string | undefined) => {
    return {
        type: SET_CURRENT_WIX_VALUE,
        payload: { keyword, number_of_topic, generator }
    }
}
export const setCurrentWixTopicArray = (topic_array: string[] | undefined) => {
    return {
        type: SET_CURRENT_WIX_TOPIC_ARRAY,
        payload: { topic_array }
    }
}
export const setCurrentImgArray = (img_array: string[] | undefined) => {
    return {
        type: SET_CURRENT_WIX_IMAGE_ARRAY,
        payload: { img_array }
    }
}
