import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Grid, Paper, List, Button } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import Accordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import history from 'modules/app/components/history'
import Box from '@mui/material/Box';
import axios, { AxiosRequestConfig } from 'axios'
import { generate_text_to_speech } from 'modules/api/market-api'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Ai_voice = (props: any) => {
    // const audioRef = useRef()
    const audioRef = useRef<HTMLAudioElement>(null);
    const [ keyword, setKeyword ] = useState<string>("")
    const [ audioURL, setAudioURL ] = useState<string>("");
    const [ isLoading, setIsLoading ] = useState<boolean>(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }

    const generate_voice = async () => {
        // console.log('generate voice')

        if ( keyword != "") {
            // generate_text_to_speech(keyword)
            //     .then(result => {
            //         const data = result.data
            //         const blob = new Blob([data], { type: 'audio/mpeg' });
            //         console.log(blob)
            //         // Create a URL for the blob object
            //         const url = URL.createObjectURL(blob);
            //         // Set the audio URL state variable to the newly created URL
            //         console.log(url)
            //         if(audioRef.current){
            //             // audioRef.current.pause();
            //             audioRef.current.load();
            //             // audioRef.current.play();
            //         }
            //         setAudioURL(url);
            //     })

             // Call the textToSpeech function to generate the audio data for the text "Hello welcome"
             setIsLoading(true)
             const data = await textToSpeech(keyword)
            // console.log(data)
            // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
            const blob = new Blob([data], { type: 'audio/mpeg' });
            // console.log(blob)
            // Create a URL for the blob object
            const url = URL.createObjectURL(blob);
            // Set the audio URL state variable to the newly created URL
            // console.log(url)
            setAudioURL(url);
            if(audioRef.current){
                // audioRef.current.pause();
                audioRef.current.load();
                // audioRef.current.play();
            }
            setIsLoading(false)
            
        }
        
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ( e.key == 'Enter' && keyword != "") {
        }
    }

    useEffect(() => {
        
    },[])

    const textToSpeech = async (inputText: string) => {
        // Set the API key for ElevenLabs API. 
        // Do not use directly. Use environment variables.
        const API_KEY = '7a7880c461a2b2a72c28c48385a494a1';
        // Set the ID of the voice to be used.
        const VOICE_ID = 'VR6AewLTigWG4xSOukaG';
        // const VOICE_ID = '21m00Tcm4TlvDq8ikWAM';
      
        // Set options for the API request.
        const options:AxiosRequestConfig = {
          method: 'POST',
          url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
          headers: {
            accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
            'content-type': 'application/json', // Set the content type to application/json.
            'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
          },
          data: {
            text: inputText, // Pass in the inputText as the text to be converted to speech.
          },
          responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
        };
      
        // Send the API request using Axios and wait for the response.
        const speechDetails = await axios.request(options);
      
        // Return the binary audio data received from the API response.
        // console.log(speechDetails.data)
        return speechDetails.data;
      };

    return (
        isLoading == true ? 
        <> 
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
                <p>&nbsp;&nbsp; Loading Data ...</p>
            </Backdrop>
            <CustomWrapperInit>
                <CustomGrid id="mainGrid" container spacing={0}>
                    <CustomGrid item sm={12}>

                    </CustomGrid>
                    <CustomGrid item sm={12} className="content_grid">
                        <div>
                            <div className='top_div'>
                                <div className='top_div_main'>
                                    <div className='top_div_top'>AI Voice</div>
                                    <div className='top_div_bottom'>flashanswers ai voice generator</div>
                                </div>
                            </div>
                            <div className='middle_div'>
                                {/* <TextField id="outlined-basic" variant="outlined" placeholder='Please input the keyword for questions and answers' sx={{width: '100%'}}/> */}
                                <TextField 
                                    variant="outlined" 
                                    label=""
                                    fullWidth
                                    autoFocus
                                    multiline
                                    rows={5}
                                    margin="normal"
                                    style={{ margin: "0px" }}
                                    sx={{width: '95%'}}
                                    value={keyword}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                                 <div className='middle_div_generate_button'>
                                    <Button variant="contained" className="non_upper" onClick={generate_voice}>generate voice</Button>
                                </div>
                            </div>
                           
                            <div className='bottom_div'>
                                <Box className="main_paper">
                                    <List component="nav" aria-label="secondary mailbox folder">
                                        <div>
                                        {audioURL && (
                                            // <audio autoPlay controls ref={audioRef}>
                                            <audio controls ref={audioRef}>
                                            <source src={audioURL} type="audio/mpeg" />
                                            </audio>
                                        )}
                                        </div>
                                    </List>
                                </Box>
                            </div>
                        </div>
                    </CustomGrid>
                    <CustomGrid item sm={12}>

                    </CustomGrid>
                </CustomGrid>
            </CustomWrapperInit>
        </>:    
        <>
            <CustomWrapperInit>
                <CustomGrid id="mainGrid" container spacing={0}>
                    <CustomGrid item sm={12}>

                    </CustomGrid>
                    <CustomGrid item sm={12} className="content_grid">
                        <div>
                            <div className='top_div'>
                                <div className='top_div_main'>
                                    <div className='top_div_top'>AI Voice</div>
                                    <div className='top_div_bottom'>flashanswers ai voice generator</div>
                                </div>
                            </div>
                            <div className='middle_div'>
                                {/* <TextField id="outlined-basic" variant="outlined" placeholder='Please input the keyword for questions and answers' sx={{width: '100%'}}/> */}
                                <TextField 
                                    variant="outlined" 
                                    label=""
                                    fullWidth
                                    autoFocus
                                    multiline
                                    rows={5}
                                    margin="normal"
                                    style={{ margin: "0px" }}
                                    sx={{width: '95%'}}
                                    value={keyword}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                                 <div className='middle_div_generate_button'>
                                    <Button variant="contained" className="non_upper" onClick={generate_voice}>generate voice</Button>
                                </div>
                            </div>
                           
                            <div className='bottom_div'>
                                <Box className="main_paper">
                                    <List component="nav" aria-label="secondary mailbox folder">
                                        <div>
                                        {audioURL && (
                                            <audio autoPlay controls ref={audioRef}>
                                            <source src={audioURL} type="audio/mpeg" />
                                            </audio>
                                        )}
                                        </div>
                                    </List>
                                </Box>
                            </div>
                        </div>
                    </CustomGrid>
                    <CustomGrid item sm={12}>

                    </CustomGrid>
                </CustomGrid>
            </CustomWrapperInit>
        </>                                                             
    );
};

const CustomAccordion = styled(Accordion)`
    width: 100%!important;
    .italic_style {
        font-style: italic;
    }
`
const CustomTypography = styled(Typography)`
    font-size: 16px!important;
    font-weight: 600!important;
`
const Toptitle = styled.span`
    font-size: 32px;
    font-weight: 700;
    margin: 10px 0;
`

const CustomGrid = styled(Grid)`
    &#mainGrid {
        margin-bottom: 5px;
        min-height: 85vh;
    }
    &.content_grid {
        display: flex!important;
        justify-content: center!important;

        .top_div {
            // background-color: #dff0f74d;
            // height: 690px;
            position: relative;
            display: flex;
            -webkit-box-align: center;
            // align-items: center;
            .top_div_main {
                padding-left: 24px;
                padding-right: 24px;
                width: 100%;
                margin-left: auto;
                box-sizing: border-box;
                margin-right: auto;
                display: block;
                .top_div_top {
                    // color: #05426c;
                    text-align: center;
                    font-family: Roboto,sans-serif;
                    font-weight: 400;
                    line-height: 1.2;
                    letter-spacing: 6px;
                    font-size: 1.25rem;
                    padding-top: 20px;
                }
                .top_div_bottom {
                    line-height: 32px;
                    font-size: 2.75rem;
                    font-family: Oswald,sans-serif;
                    font-weight: 700;
                    line-height: 1.1;
                    letter-spacing: 0.01em;
                    text-align: center;
                    max-width: 728px;
                    padding: 10px 0 25px;
                    margin: 0 auto;
                    box-sizing: inherit;
                }
            }
        }

        .middle_div {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            flex-flow: column;
        }

        .middle_div_generate_button {
            width: 95%;
            position: relative;
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
        }

        .bottom_div {
            // position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 10px!important;
            .main_paper{
                width: 95%!important;
                max-height: 200px!important;
                overflow: auto!important;
                box-sizing: border-box!important;
            }
            .content_list{
                padding-left: 15px!important;
                border-bottom: 1px solid gray;
                .edit_icon:hover {
                    color: #0b9bef;
                }
                .delete_icon:hover {
                    color: #ef1634;
                }
            }
            // .content_list:hover {
            //     background-color: #a885e6!important;
            //     color: white!important;
            //     cursor: pointer!important;
            // }
        }
    }
`

const CustomWrapperInit = styled.div`
    // min-height: 100vh;
    @media (max-width: ${screenSizes.mediaM}px) {
        // display: none;
    }
    
`
export default Ai_voice