import { combineReducers } from 'redux'
import { baseReducer } from './base.reducer'
import { productReducer } from './product.reducer'
import { gclidReducer } from './gclid.reducer'
import { wixReducer } from './wix.reducer'
import { draftReducer } from './draft.reducer'
import { drafUpdatetReducer } from './draft_update.reducer'

const rootReducer = combineReducers({
  base: baseReducer,
  product: productReducer,
  gclid: gclidReducer,
  wix: wixReducer,
  draft: draftReducer,
  draft_update: drafUpdatetReducer,
})
export default rootReducer
