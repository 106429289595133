import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Grid, Paper, List } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { publish_post02, schedule_article } from 'modules/api/market-api'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { setCurrentDraft, setCurrentWixValue, setCurrentWixTopicArray } from 'logic/action/base.action'
import history from 'modules/app/components/history'
import CollectionsIcon from '@mui/icons-material/Collections';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Dialog_img_picker from './ImgDialog'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import moment from 'moment';

const Draft = (props: any) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: any) => state)
    const [ site, setSite ] = useState<string>('hairme');
    const [ title, setTitle ] = useState<string>('')
    const [ description, setDescription ] = useState<string>('')
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ imgDialogOpen, setImgDialogOpen ] = useState<boolean>(false)
    const [ img, setImg ] = useState<string>('')
    const [ imgArr, setImgArr ] = useState<any>()
    const [ scheduleEnable, setScheduleEnable ] = useState<boolean>(false)
    const [ scheduleDateTime, setScheduleDateTime ] = useState<any>(new Date());

    // useEffect(() => {
    //     console.log(value)
    // }, [value])

    const handleSiteChange = (event: SelectChangeEvent) => {
        setSite(event.target.value);
    };

    const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
    }

    useEffect(() => {
        setTitle(selector.draft.currentTitle)
        setDescription(selector.draft.currentDescription)
    },[selector.draft.currentTitle, selector.draft.currentDescription])

    useEffect(() => {
        setImgArr(selector.wix.currentImgArray)
    },[selector.wix.currentImgArray])

    const handleSave = () => {
        if(title.trim() == '' || description.trim() == '') {
            // console.log('no publish')
        } else {
            let arr = [...selector.wix.currentTopicArray]
            setIsLoading(true)

            if ( scheduleEnable == false ) {
                publish_post02(title, description, site, img)
                    .then(result => {
                        arr.splice(selector.draft.currentIndex, 1)
                        dispatch(setCurrentWixTopicArray(arr))
                        setIsLoading(false)
                        history.push(`/wix`)
                    })
                    .catch(err => {
                        // console.log(err)
                        setIsLoading(false)
                        history.push(`/wix`)
                    })
            } else if( scheduleEnable == true ) {
                // console.log(scheduleDateTime)
                // console.log(moment('2023-05-11T04:50:00+03:00'))
                let schedule_date_time = moment(scheduleDateTime).format()
                // console.log(schedule_date_time)
                // console.log(typeof schedule_date_time)
                schedule_article(title, description, site, img, schedule_date_time)
                    .then(result => {
                        // console.log(result.data.status)
                        arr.splice(selector.draft.currentIndex, 1)
                        dispatch(setCurrentWixTopicArray(arr))
                        setIsLoading(false)
                        history.push(`/wix`)
                    })
                    .catch(err => {
                        // console.log(err)
                        setIsLoading(false)
                        history.push(`/wix`)
                    })
            }
        }
    };

    const handleClose = () => {
        // setOpen(false);
        // props.setOpen(false)
        history.push(`/wix`)
    };

    const handleSelectImg = () => {
        // e.stopPropagation()
        setImgDialogOpen(true)
    }

    function handleScheduleShowClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        // console.log(moment.utc(new Date()).format())

        event.preventDefault();
        setScheduleEnable(true)
        console.info('Show Schedule');
    }

    function handleScheduleHideClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        setScheduleEnable(false)
        console.info('Hide Schedule');
    }

    return (
        isLoading == true ? 
        <> 
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
                <p>&nbsp;&nbsp; Loading Data ...</p>
            </Backdrop>
            <CustomWrapperInit>
                <CustomGrid id="mainGrid" container spacing={0}>
                    <CustomGrid item xs={12} sm={2}>

                    </CustomGrid>
                    <CustomGrid item xs={12} sm={8} className="content_grid flex_direction_column">
                            <div className='top_div'>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="outlined" onClick={handleSave}>Publish</Button>
                                    <Button variant="outlined" color="error" onClick={handleClose}>Cancel</Button>
                                </Stack>
                            </div>
                            <div className='schedule_div'>
                                {
                                scheduleEnable == false ? 
                                    <>
                                    <div className='align_right'>
                                        <Link
                                            underline="hover"
                                            color="inherit"
                                            href="/material-ui/getting-started/installation/"
                                            onClick={handleScheduleShowClick}
                                        >
                                            <Typography color="info.main" sx={{ "&:hover": { color: "text.primary" } }}>
                                                Set Schedule
                                            </Typography>
                                        </Link>
                                    </div>
                                    </>
                                :   
                                    <>
                                    <div className='align_right'>
                                        <Link
                                            underline="hover"
                                            color="inherit"
                                            href="/material-ui/getting-started/installation/"
                                            onClick={handleScheduleHideClick}
                                        >
                                            <Typography color="info.main" sx={{ "&:hover": { color: "text.primary" } }}>
                                                Hide Schedule
                                            </Typography>
                                        </Link>
                                    </div>
                                    <div className='align_right'>
                                        <DateTimePicker onChange={setScheduleDateTime} value={scheduleDateTime} />
                                    </div>
                                    </>  
                                }
                            </div>
                            <div className='middle_div'>
                                <CustomGrid container spacing={0}>
                                    <CustomGrid item xs={12} sm={2}>
                                        <DialogContentText>
                                            site:
                                        </DialogContentText>
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={10}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={site}
                                            onChange={handleSiteChange}
                                            sx={{ mb: 1, width: '100%' }}
                                        >
                                            {/* <MenuItem value="ourmonet">Ourmonet</MenuItem> */}
                                            <MenuItem value="hairme">HAIR ME</MenuItem>
                                            <MenuItem value="remotion">REMOTION</MenuItem>
                                            <MenuItem value="home_design_perspect">Home Design Perspect</MenuItem>
                                        </Select>
                                    </CustomGrid>
                                </CustomGrid>

                                <CustomGrid container spacing={0}>
                                    <CustomGrid item xs={12} sm={2}>
                                        <DialogContentText>
                                            title:
                                        </DialogContentText>
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={10}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            // id="name"
                                            // label="question"
                                            // type="email"
                                            fullWidth
                                            // variant="standard"
                                            value={title}
                                            onChange={handleQuestionChange}
                                        />
                                    </CustomGrid>
                                </CustomGrid>
                                
                                <CustomGrid container spacing={0}>
                                    <CustomGrid item xs={12} sm={2}>
                                        <DialogContentText margin="dense">
                                            description:    
                                        </DialogContentText>
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={10}>
                                        <TextField
                                            margin="dense"
                                            // label="answer"
                                            fullWidth
                                            multiline
                                            rows={8}
                                            value={description}
                                            onChange={handleAnswerChange}
                                        />
                                    </CustomGrid>
                                </CustomGrid>
                            </div>
                            <div >
                                <CustomGrid container spacing={0}>
                                    <CustomGrid item xs={12} sm={2}>
                                        
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={10} className='bottom_div'>
                                        <Stack direction="row" spacing={2}>
                                            <Button variant="text" startIcon={<PhotoSizeSelectActualIcon/>} onClick={(e) => {handleSelectImg()}}>
                                                Choose Image
                                            </Button>
                                            {/* <Button variant="contained" endIcon={<AddPhotoAlternateIcon/>}>
                                                Choose Image
                                            </Button> */}
                                        </Stack>
                                    </CustomGrid>
                                </CustomGrid>
                                <CustomGrid container spacing={0}>
                                    <CustomGrid item xs={12} sm={2}>
                                        
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={10} className='bottom_div'>
                                        {/* <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}> */}
                                        {/* <ImageList cols={3} rowHeight={164}> */}
                                        <ImageList >
                                            <ImageListItem>
                                                <img
                                                    src={`${img}?w=200&h=200&fit=crop&auto=format`}
                                                    srcSet={`${img}?w=200&h=200&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={img}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>
                                        </ImageList>
                                    </CustomGrid>
                                </CustomGrid>
                            </div>
                    </CustomGrid>
                    <CustomGrid item xs={12} sm={2}>

                    </CustomGrid>
                </CustomGrid>
            </CustomWrapperInit>
        </> :
        <>
            <CustomWrapperInit>
                <CustomGrid id="mainGrid" container spacing={0}>
                    <CustomGrid item xs={12} sm={2}>

                    </CustomGrid>
                    <CustomGrid item xs={12} sm={8} className="content_grid flex_direction_column">
                            <div className='top_div'>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="outlined" onClick={handleSave}>Publish</Button>
                                    <Button variant="outlined" color="error" onClick={handleClose}>Cancel</Button>
                                </Stack>
                            </div>
                            <div className='schedule_div'>
                                {
                                scheduleEnable == false ? 
                                    <>
                                    <div className='align_right'>
                                        <Link
                                            underline="hover"
                                            color="inherit"
                                            href="/material-ui/getting-started/installation/"
                                            onClick={handleScheduleShowClick}
                                        >
                                            <Typography color="info.main" sx={{ "&:hover": { color: "text.primary" } }}>
                                                Set Schedule
                                            </Typography>
                                        </Link>
                                    </div>
                                    </>
                                :   
                                    <>
                                    <div className='align_right'>
                                        <Link
                                            underline="hover"
                                            color="inherit"
                                            href="/material-ui/getting-started/installation/"
                                            onClick={handleScheduleHideClick}
                                        >
                                            <Typography color="info.main" sx={{ "&:hover": { color: "text.primary" } }}>
                                                Hide Schedule
                                            </Typography>
                                        </Link>
                                    </div>
                                    <div className='align_right'>
                                        <DateTimePicker onChange={setScheduleDateTime} value={scheduleDateTime} />
                                    </div>
                                    </>  
                                }
                            </div>
                            <div className='middle_div'>
                                <CustomGrid container spacing={0}>
                                    <CustomGrid item xs={12} sm={2}>
                                        <DialogContentText>
                                            site:
                                        </DialogContentText>
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={10}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={site}
                                            onChange={handleSiteChange}
                                            sx={{ mb: 1, width: '100%' }}
                                        >
                                            {/* <MenuItem value="ourmonet">Ourmonet</MenuItem> */}
                                            <MenuItem value="hairme">HAIR ME</MenuItem>
                                            <MenuItem value="remotion">REMOTION</MenuItem>
                                            <MenuItem value="home_design_perspect">Home Design Perspect</MenuItem>
                                        </Select>
                                    </CustomGrid>
                                </CustomGrid>

                                <CustomGrid container spacing={0}>
                                    <CustomGrid item xs={12} sm={2}>
                                        <DialogContentText>
                                            title:
                                        </DialogContentText>
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={10}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            // id="name"
                                            // label="question"
                                            // type="email"
                                            fullWidth
                                            // variant="standard"
                                            value={title}
                                            onChange={handleQuestionChange}
                                        />
                                    </CustomGrid>
                                </CustomGrid>
                                
                                <CustomGrid container spacing={0}>
                                    <CustomGrid item xs={12} sm={2}>
                                        <DialogContentText margin="dense">
                                            description:    
                                        </DialogContentText>
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={10}>
                                        <TextField
                                            margin="dense"
                                            // label="answer"
                                            fullWidth
                                            multiline
                                            rows={8}
                                            value={description}
                                            onChange={handleAnswerChange}
                                        />
                                    </CustomGrid>
                                </CustomGrid>
                            </div>
                            <div >
                                <CustomGrid container spacing={0}>
                                    <CustomGrid item xs={12} sm={2}>
                                        
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={10} className='bottom_div'>
                                        <Stack direction="row" spacing={2}>
                                            <Button variant="text" startIcon={<PhotoSizeSelectActualIcon/>} onClick={(e) => {handleSelectImg()}}>
                                                Choose Image
                                            </Button>
                                            {/* <Button variant="contained" endIcon={<AddPhotoAlternateIcon/>}>
                                                Choose Image
                                            </Button> */}
                                        </Stack>
                                    </CustomGrid>
                                </CustomGrid>
                                <CustomGrid container spacing={0}>
                                    <CustomGrid item xs={12} sm={2}>
                                        
                                    </CustomGrid>
                                    <CustomGrid item xs={12} sm={10} className='bottom_div'>
                                        {/* <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}> */}
                                        {/* <ImageList cols={3} rowHeight={164}> */}
                                        <ImageList >
                                            <ImageListItem>
                                                <img
                                                    src={`${img}?w=200&h=200&fit=crop&auto=format`}
                                                    srcSet={`${img}?w=200&h=200&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={img}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>
                                        </ImageList>
                                    </CustomGrid>
                                </CustomGrid>
                            </div>
                    </CustomGrid>
                    <CustomGrid item xs={12} sm={2}>

                    </CustomGrid>
                </CustomGrid>
            </CustomWrapperInit>
            <Dialog_img_picker open={imgDialogOpen} setOpen={setImgDialogOpen} setImg={setImg} imgArr={imgArr}/>
        </>                                                                      
    );
};

const CustomTextField = styled(TextField)`
    &.number_topic {
        margin-top: 10px!important;
    }
`

const CustomGrid = styled(Grid)`
    &#mainGrid {
        margin-top: 10px;
        margin-bottom: 5px;
        min-height: 80vh;
    }
    &.flex_direction_column {
        flex-direction: column!important;
    }

    &.content_grid {
        display: flex!important;
        justify-content: flex-start!important;

        
        .middle_div {
            position: relative;
            display: flex;
            flex-direction: column!important;
            justify-content: center;
            width: 100%;
        }
        .top_div {
            display: flex;
            justify-content: flex-end!important;
            padding: 10px 0px!important;
        }
        .schedule_div {
            display: flex;
            flex-direction: column;
            // justify-content: flex-end!important;
            padding: 10px 0px!important;
            .align_right {
                display: flex;
                justify-content: flex-end;
            }
        }
        .bottom_div {
            display: flex;
            justify-content: flex-start!important;
            padding: 10px 0px!important;
        }
    }
`

const CustomWrapperInit = styled.div`
    // min-height: 100vh;
    @media (max-width: ${screenSizes.mediaM}px) {
        // display: none;
    }
    
`
export default Draft