import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Grid, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProductBoxMobile from './components/ProductBoxMobile';
import { setCurrentProductUrl } from 'logic/action/base.action'
import history from 'modules/app/components/history'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'

const MobileDiv = (props: any) => {
    const { items, onChangeStartDate, onChangeEndDate, value_start_date, value_end_date } = props
    const dispatch = useDispatch()

    return (
        <CustomWrapperItems>
            <CustomGrid container spacing={0} >
                <CustomGrid item container spacing={0} md={12} className="grid-header-left grid-flex-item-start">
                    <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                        <CustomGrid item sm={12} className="margin-padding-0_v-center">
                            <div className="top_div_main_right">
                                <CustomDatePicker onChange={onChangeStartDate} value={value_start_date} />
                                <div className="top_div_main_right_dash"> &nbsp; - &nbsp; </div>
                                <CustomDatePicker onChange={onChangeEndDate} value={value_end_date} />
                            </div>
                        </CustomGrid>
                    </CustomGrid>
                </CustomGrid>
            </CustomGrid> 
            {/* <Divider sx={{ boxShadow: '0px 1px 2px #0a0909'}} /> */}
            <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '5px!important'}}>
                <CustomGrid item container spacing={0} sm={12} className="grid-header-left grid-header-right grid-flex-item-start" sx={{background: 'transparent!important'}} >
                    {   items.length !== 0 ?
                        items.length > 0 && items.map((item: any, index: number)=> 
                            <ProductBoxMobile key={index} item={item} />
                        ) : ''
                    }
                </CustomGrid>
            </CustomGrid>

        </CustomWrapperItems> 
    );
};

const CustomWrapperItems = styled.div`
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`
const CustomChevronRightIcon = styled(ChevronRightIcon)`
    margin: 0 5px;
`

const ImageAndTextM = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 5px;
  img {
    width: 25px!important;
    height: 25px!important;
    margin-right: 5px;
  }
`

const CardSubTextM = styled.p`
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    color: #06bbf1;
    font-family: 'Montserrat',sans-serif;
    margin: 0;
`



const CustomGrid = styled(Grid)`
  background-color: white!important;

  padding: 2px 10px;
  margin: 0px!important;

  img {
    width: 192px;
    height: 48px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-left: 185px!important;
    }
  }

  .grid-header-right {
    align-items: center;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
  .margin-padding-0_v-center {
    display: flex;
    align-items: center;
    margin: 2px 0!important;
    padding: 0!important;
  }
 
  .imageTxt {
      margin: 0 10px 0 5px;
      color: gray!important;
  }

  .top_div_main_right {
    // font-size: 20px;
    // font-weight: 500;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    .top_div_main_right_dash {
      display: flex;
      align-items: center;
    }
  }
`
const ShortSpan = styled.span`
    cursor: pointer!important;
    font-family: sans-serif;
    color: gray;
    &:hover {
        color: black;
    }
    &.lastSpan {
        color: #37a4f1!important;
    }
`
const CategoryTitle = styled.span`
    font-family: sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 20px;
`
const UpdatedSpan = styled.span`
    font-family: system-ui;
    color: gray!important;
    font-size: 15px;
`

const CustomDatePicker = styled(DatePicker)`
  .react-date-picker__button {
    padding: 4px 2px !important;
  }
  .react-date-picker__wrapper {
    border-radius: 3px !important;
  }
`

export default MobileDiv