import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Grid, Paper, List, Button } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import Accordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { get_all_campaigns } from 'modules/api/market-api'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import { alpha } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import { visuallyHidden } from '@mui/utils'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import MobileDiv from './MobileDiv'

interface Data {
  date: string
  campaign: string
  searches: number
  clicks: number
  estimated_revenue: number
  revenue_per_click: number
  cost: number
  profit: number
  last_updated: string
}

type Order = 'asc' | 'desc'

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'date',
  },
  {
    id: 'campaign',
    numeric: true,
    disablePadding: false,
    label: 'campaign',
  },
  {
    id: 'searches',
    numeric: true,
    disablePadding: false,
    label: 'searches',
  },
  {
    id: 'clicks',
    numeric: true,
    disablePadding: false,
    label: 'clicks',
  },
  {
    id: 'estimated_revenue',
    numeric: true,
    disablePadding: false,
    label: 'estimated_revenue',
  },
  {
    id: 'revenue_per_click',
    numeric: true,
    disablePadding: false,
    label: 'revenue_per_click',
  },
  {
    id: 'cost',
    numeric: true,
    disablePadding: false,
    label: 'cost',
  },
  {
    id: 'profit',
    numeric: true,
    disablePadding: false,
    label: 'profit',
  },
  {
    id: 'last_updated',
    numeric: true,
    disablePadding: false,
    label: 'last_updated',
  },
]

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
  sort_flag: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, sort_flag } = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>
          // headCell.id == 'date' || headCell.id == 'last_updated'?
          headCell.id == 'date' ? (
            <TableCell
              // padding="checkbox"
              key={headCell.id}
              align="center"
              // align={headCell.numeric ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {sort_flag == true ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <>{headCell.label}</>
              )}
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align="center"
              // align={headCell.numeric ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
}

function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
  return array.reduce((store: any, item: any) => {
    var key = grouper(item)
    if (!store.has(key)) {
      store.set(key, [item])
    } else {
      store?.get(key).push(item)
    }
    return store
  }, new Map<K, V[]>())
}

function formatDate(date: any) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

const Campaign = (props: any) => {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('date')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5)
  const [visible_rows, setVisibleRows] = React.useState<any>([])
  const [filtered_rows, setFilteredRows] = React.useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [rows, setRows] = React.useState<any>([])
  const [value_start_date, onChangeStartDate] = useState<any>('')
  const [value_end_date, onChangeEndDate] = useState<any>('')

  useEffect(() => {
    setIsLoading(true)
    get_all_campaigns().then((result) => {
        // console.log('=======================================================start')
        let result_rows = result.data.data.campaigns
        // console.log(result_rows)
        setIsLoading(false)
        result_rows = result_rows.map((row:any, index:number) => {
            let revenue_per_click = (row.estimated_revenue / row.clicks).toFixed(2)
            row = Object.assign(row, { revenue_per_click: revenue_per_click })
            row = Object.assign(row, { profit: (row.estimated_revenue - row.cost).toFixed(2) })
            return row
        })
        
        setRows(result_rows)
        setFilteredRows(result_rows)
        let current = new Date();
      //   console.log(current)
      //   var d = new Date(current)
      //   var month = '' + (d.getMonth() + 1)
      //   var day = '' + d.getDate()
      //   var year = d.getFullYear()
    
      // if (month.length < 2) month = '0' + month
      // if (day.length < 2) day = '0' + day
      //   console.log(month, day, year)

        let current_date = formatDate(current)
        // console.log(current_date)
        onChangeStartDate(current_date + 'T00:00:00')
        onChangeEndDate(current_date + 'T00:00:00')
    })
  },[])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (event: React.MouseEvent<unknown>, date: string) => {
    // console.log(date)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    let sd = new Date(value_start_date).getTime()
    let ed = new Date(value_end_date).getTime()
    // console.log(new Date(value_start_date))
    // console.log(new Date(value_end_date))

    let filtered_rows_temp = rows

    if (value_start_date != '' && value_start_date != null && value_end_date != '' && value_end_date != null) {
      // console.log('case1')

      filtered_rows_temp = filtered_rows_temp.filter((row: any) => {
        let row_date = new Date(row.date).getTime()
        // console.log(sd, ed, row_date, value_start_date, value_end_date, row.date)
        return sd <= row_date && row_date <= ed
      })

      let grouped_obj = groupBy(filtered_rows_temp, (x: any) => x.campaign)
      // console.log(grouped_obj)

      let grouped_key_array = [] as any[]
      let grouped_value_array = [] as any[]

      grouped_obj.forEach(function (value: any, key: any) {
        // console.log(key, value)
        grouped_key_array.push(key)
        grouped_value_array.push(value)
      })

      let new_filtered_rows_temp = [] as any[]

      for (let i = 0; i < grouped_key_array.length; i++) {
        let temp_obj = {}
        temp_obj = Object.assign(temp_obj, { date: formatDate(value_start_date) + ' ~ ' + formatDate(value_end_date) })
        temp_obj = Object.assign(temp_obj, { campaign: grouped_key_array[i] })
        let temp_searches = 0
        let temp_clicks = 0
        let temp_estimated_revenue = 0
        let temp_cost = 0
        let temp_estimated_last_updated = grouped_value_array[i][0].last_updated

        for (let j = 0; j < grouped_value_array[i].length; j++) {
          temp_searches = temp_searches + grouped_value_array[i][j].searches
          temp_clicks = temp_clicks + grouped_value_array[i][j].clicks
          temp_estimated_revenue = temp_estimated_revenue + grouped_value_array[i][j].estimated_revenue
          if( grouped_value_array[i][j].cost != '' ) {
            temp_cost = temp_cost + grouped_value_array[i][j].cost
          }

          temp_estimated_last_updated =
            new Date(temp_estimated_last_updated).getTime() > new Date(grouped_value_array[i][j].last_updated).getTime()
              ? temp_estimated_last_updated
              : grouped_value_array[i][j].last_updated
        }

        temp_obj = Object.assign(temp_obj, { searches: temp_searches })
        temp_obj = Object.assign(temp_obj, { clicks: temp_clicks })
        temp_obj = Object.assign(temp_obj, { estimated_revenue: temp_estimated_revenue.toFixed(2) })
        temp_obj = Object.assign(temp_obj, { revenue_per_click: (parseFloat(temp_estimated_revenue.toFixed(2)) / temp_clicks).toFixed(2) })
        temp_obj = Object.assign(temp_obj, { cost: temp_cost != 0 ? temp_cost.toFixed(2) : '' })
        temp_obj = Object.assign(temp_obj, { profit: (temp_estimated_revenue - temp_cost).toFixed(2) })
        temp_obj = Object.assign(temp_obj, { last_updated: temp_estimated_last_updated })

        new_filtered_rows_temp.push(temp_obj)
      }

      // console.log(grouped_key_array)
      // console.log(grouped_value_array)
      // console.log(new_filtered_rows_temp)

      setPage(0)
      // setFilteredRows(filtered_rows_temp)
      setFilteredRows(new_filtered_rows_temp)
    } else if ((value_start_date == '' || value_start_date == null) && (value_end_date == '' || value_end_date == null)) {
      // console.log('case2')
      setPage(0)
      setFilteredRows(rows)
    } else {
      if (value_start_date != '' && value_start_date != null) {
        // console.log('case3-1')
        filtered_rows_temp = filtered_rows_temp.filter((row: any) => {
          let row_date = new Date(row.date).getTime()
          return sd <= row_date
        })

        let grouped_obj = groupBy(filtered_rows_temp, (x: any) => x.campaign)
        // console.log(grouped_obj)

        let grouped_key_array = [] as any[]
        let grouped_value_array = [] as any[]

        grouped_obj.forEach(function (value: any, key: any) {
          // console.log(key, value)
          grouped_key_array.push(key)
          grouped_value_array.push(value)
        })

        let new_filtered_rows_temp = [] as any[]

        for (let i = 0; i < grouped_key_array.length; i++) {
          let temp_obj = {}
          temp_obj = Object.assign(temp_obj, { date: formatDate(value_start_date) + ' ~ ' })
          temp_obj = Object.assign(temp_obj, { campaign: grouped_key_array[i] })
          let temp_searches = 0
          let temp_clicks = 0
          let temp_estimated_revenue = 0
          let temp_cost = 0
          let temp_estimated_last_updated = grouped_value_array[i][0].last_updated

          for (let j = 0; j < grouped_value_array[i].length; j++) {
            temp_searches = temp_searches + grouped_value_array[i][j].searches
            temp_clicks = temp_clicks + grouped_value_array[i][j].clicks
            temp_estimated_revenue = temp_estimated_revenue + grouped_value_array[i][j].estimated_revenue
            if( grouped_value_array[i][j].cost != '' ) {
              temp_cost = temp_cost + grouped_value_array[i][j].cost
            }
  
            temp_estimated_last_updated =
              new Date(temp_estimated_last_updated).getTime() > new Date(grouped_value_array[i][j].last_updated).getTime()
                ? temp_estimated_last_updated
                : grouped_value_array[i][j].last_updated
          }

          temp_obj = Object.assign(temp_obj, { searches: temp_searches })
          temp_obj = Object.assign(temp_obj, { clicks: temp_clicks })
          temp_obj = Object.assign(temp_obj, { estimated_revenue: temp_estimated_revenue.toFixed(2) })
          temp_obj = Object.assign(temp_obj, { revenue_per_click: (parseFloat(temp_estimated_revenue.toFixed(2)) / temp_clicks).toFixed(2) })
          temp_obj = Object.assign(temp_obj, { cost: temp_cost != 0 ? temp_cost.toFixed(2) : '' })
          temp_obj = Object.assign(temp_obj, { profit: (temp_estimated_revenue - temp_cost).toFixed(2) })
          temp_obj = Object.assign(temp_obj, { last_updated: temp_estimated_last_updated })

          new_filtered_rows_temp.push(temp_obj)
        }

        // console.log(grouped_key_array)
        // console.log(grouped_value_array)
        // console.log(new_filtered_rows_temp)
        setPage(0)
        // setFilteredRows(filtered_rows_temp)
        setFilteredRows(new_filtered_rows_temp)
      }

      if (value_end_date != '' && value_end_date != null) {
        // console.log('case3-2')
        filtered_rows_temp = filtered_rows_temp.filter((row: any) => {
          let row_date = new Date(row.date).getTime()
          return row_date <= ed
        })

        let grouped_obj = groupBy(filtered_rows_temp, (x: any) => x.campaign)
        // console.log(grouped_obj)

        let grouped_key_array = [] as any[]
        let grouped_value_array = [] as any[]

        grouped_obj.forEach(function (value: any, key: any) {
          // console.log(key, value)
          grouped_key_array.push(key)
          grouped_value_array.push(value)
        })

        let new_filtered_rows_temp = [] as any[]

        for (let i = 0; i < grouped_key_array.length; i++) {
          let temp_obj = {}
          temp_obj = Object.assign(temp_obj, { date: ' ~ ' + formatDate(value_end_date) })
          temp_obj = Object.assign(temp_obj, { campaign: grouped_key_array[i] })
          let temp_searches = 0
          let temp_clicks = 0
          let temp_estimated_revenue = 0
          let temp_cost = 0
          let temp_estimated_last_updated = grouped_value_array[i][0].last_updated

          for (let j = 0; j < grouped_value_array[i].length; j++) {
            temp_searches = temp_searches + grouped_value_array[i][j].searches
            temp_clicks = temp_clicks + grouped_value_array[i][j].clicks
            temp_estimated_revenue = temp_estimated_revenue + grouped_value_array[i][j].estimated_revenue
            if( grouped_value_array[i][j].cost != '' ) {
              temp_cost = temp_cost + grouped_value_array[i][j].cost
            }
  
            temp_estimated_last_updated =
              new Date(temp_estimated_last_updated).getTime() > new Date(grouped_value_array[i][j].last_updated).getTime()
                ? temp_estimated_last_updated
                : grouped_value_array[i][j].last_updated
          }

          temp_obj = Object.assign(temp_obj, { searches: temp_searches })
          temp_obj = Object.assign(temp_obj, { clicks: temp_clicks })
          temp_obj = Object.assign(temp_obj, { estimated_revenue: temp_estimated_revenue.toFixed(2) })
          temp_obj = Object.assign(temp_obj, { revenue_per_click: (parseFloat(temp_estimated_revenue.toFixed(2)) / temp_clicks).toFixed(2) })
          temp_obj = Object.assign(temp_obj, { cost: temp_cost != 0 ? temp_cost.toFixed(2) : '' })
          temp_obj = Object.assign(temp_obj, { profit: (temp_estimated_revenue - temp_cost).toFixed(2) })
          temp_obj = Object.assign(temp_obj, { last_updated: temp_estimated_last_updated })

          new_filtered_rows_temp.push(temp_obj)
        }

        // console.log(grouped_key_array)
        // console.log(grouped_value_array)
        // console.log(new_filtered_rows_temp)

        setPage(0)
        // setFilteredRows(filtered_rows_temp)
        setFilteredRows(new_filtered_rows_temp)
      }
    }
  }, [value_start_date, value_end_date])

  const visibleRows = React.useMemo(() => {

    // console.log(rows)
    // console.log(filtered_rows)
    let n_rows = filtered_rows

    if ((value_start_date == '' || value_start_date == null) && (value_end_date == '' || value_end_date == null)) {
      n_rows.sort((a: Data, b: Data) => {
        if (order == 'asc') {
          if (orderBy == 'date') {
            let ad = new Date(a.date)
            let bd = new Date(b.date)
            return ad.getTime() - bd.getTime()
            // return bd.getTime() - ad.getTime();
          } else if (orderBy == 'last_updated') {
            let ad = new Date(a.last_updated)
            let bd = new Date(b.last_updated)
            return ad.getTime() - bd.getTime()
          }
        } else if (order == 'desc') {
          if (orderBy == 'date') {
            let ad = new Date(a.date)
            let bd = new Date(b.date)
            // return ad.getTime() - bd.getTime();
            return bd.getTime() - ad.getTime()
          } else if (orderBy == 'last_updated') {
            let ad = new Date(a.last_updated)
            let bd = new Date(b.last_updated)
            return bd.getTime() - ad.getTime()
          }
        }
      })
    }

    let visible_arr = n_rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    setVisibleRows(visible_arr)
    // console.log(order, orderBy, page, rowsPerPage)
  }, [order, orderBy, page, rowsPerPage, filtered_rows])

  return isLoading == true ? (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        <p>&nbsp;&nbsp; Loading Data ...</p>
      </Backdrop>
      <CustomWrapperInit>
        <CustomGrid id="mainGrid" container spacing={0}>
          <CustomGrid item sm={12} className="content_grid">
            <div className="content_body">
              {/* <div> */}
              <div className="top_div">
                <CustomGrid container spacing={0}>
                  <CustomGrid item sm={3}>
                    <div className="top_div_main_left">Report</div>
                  </CustomGrid>
                  <CustomGrid item sm={9}>
                    <div className="top_div_main_right">
                      <CustomDatePicker onChange={onChangeStartDate} value={value_start_date} />
                      <div className="top_div_main_right_dash"> &nbsp; - &nbsp; </div>
                      <CustomDatePicker onChange={onChangeEndDate} value={value_end_date} />
                    </div>
                  </CustomGrid>
                </CustomGrid>
              </div>
              <div className="middle_div">
                <Box sx={{ width: '100%' }}>
                  <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                      <Table aria-labelledby="tableTitle">
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          sort_flag={
                            (value_start_date == '' || value_start_date == null) && (value_end_date == '' || value_end_date == null) == true
                              ? true
                              : false
                          }
                        />
                        <TableBody>
                          {visible_rows.map((row: any, index: number) => {
                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.date)}
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                                sx={{ cursor: 'pointer' }}
                              >
                                <TableCell align="center">{row.date}</TableCell>
                                <TableCell align="center">{row.campaign}</TableCell>
                                <TableCell align="center">{row.searches}</TableCell>
                                <TableCell align="center">{row.clicks}</TableCell>
                                <TableCell align="center">{row.estimated_revenue}</TableCell>
                                <TableCell align="center">{row.estimated_revenue}</TableCell>
                                <TableCell align="center">{row.last_updated}</TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={filtered_rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Box>
              </div>

              <div className="bottom_div"></div>
            </div>
          </CustomGrid>
        </CustomGrid>
      </CustomWrapperInit>
    </>
  ) : (
    <>
      <CustomWrapperInit>
        <CustomGrid id="mainGrid" container spacing={0}>
          <CustomGrid item sm={12} className="content_grid">
            <div className="content_body">
              {/* <div> */}
              <div className="top_div">
                <CustomGrid container spacing={0}>
                  <CustomGrid item sm={3}>
                    <div className="top_div_main_left" id="report">Report</div>
                  </CustomGrid>
                  <CustomGrid item sm={9}>
                    <div className="top_div_main_right">
                      <CustomDatePicker onChange={onChangeStartDate} value={value_start_date} />
                      <div className="top_div_main_right_dash"> &nbsp; - &nbsp; </div>
                      <CustomDatePicker onChange={onChangeEndDate} value={value_end_date} />
                    </div>
                  </CustomGrid>
                </CustomGrid>
              </div>
              <div className="middle_div">
                <Box sx={{ width: '100%' }}>
                  <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                      <Table aria-labelledby="tableTitle">
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          sort_flag={
                            (value_start_date == '' || value_start_date == null) && (value_end_date == '' || value_end_date == null) == true
                              ? true
                              : false
                          }
                        />
                        <TableBody>
                          {visible_rows.map((row: any, index: number) => {
                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.date)}
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                                sx={{ cursor: 'pointer' }}
                              >
                                <TableCell align="center">{row.date}</TableCell>
                                <TableCell align="center">{row.campaign}</TableCell>
                                <TableCell align="center">{row.searches}</TableCell>
                                <TableCell align="center">{row.clicks}</TableCell>
                                <TableCell align="center">{row.estimated_revenue}</TableCell>
                                <TableCell align="center">{row.revenue_per_click}</TableCell>
                                <TableCell align="center">{row.cost}</TableCell>
                                <TableCell align="center" sx={row.profit < 0 ? { color: 'red'} : { color: 'green'}}>{row.profit}</TableCell>
                                <TableCell align="center">{row.last_updated}</TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={filtered_rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Box>
              </div>

              <div className="bottom_div"></div>
            </div>
          </CustomGrid>
        </CustomGrid>
      </CustomWrapperInit>
      {/* <MobileDiv items={visible_rows} /> */}
      <MobileDiv items={filtered_rows} onChangeStartDate={onChangeStartDate} onChangeEndDate={onChangeEndDate} value_start_date={value_start_date} value_end_date={value_end_date}/>
    </>
  )
}

const CustomDatePicker = styled(DatePicker)`
  .react-date-picker__button {
    padding: 4px 2px !important;
  }
  .react-date-picker__wrapper {
    border-radius: 3px !important;
  }
`
const CustomAccordion = styled(Accordion)`
  width: 100% !important;
  .italic_style {
    font-style: italic;
  }
`
const CustomTypography = styled(Typography)`
  font-size: 16px !important;
  font-weight: 600 !important;
`
const Toptitle = styled.span`
  font-size: 32px;
  font-weight: 700;
  margin: 10px 0;
`

const CustomGrid = styled(Grid)`
  &#mainGrid {
    margin-bottom: 5px;
    min-height: 82vh;
    padding-top: 10px;
  }
  &.content_grid {
    display: flex !important;
    justify-content: center !important;

    .content_body {
      width: 80%;
      height: 80vh;
      overflow: auto;
    }
    .top_div {
      // background-color: #dff0f74d;
      // height: 690px;
      position: relative;
      display: flex;
      -webkit-box-align: center;
      // align-items: center;
      .top_div_main_left {
        font-size: 20px;
        font-weight: 500;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 100%;
        margin-left: auto;
        box-sizing: border-box;
        margin-right: auto;
      }
      .top_div_main_right {
        // font-size: 20px;
        // font-weight: 500;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 100%;
        margin-left: auto;
        box-sizing: border-box;
        margin-right: auto;
        display: flex;
        justify-content: flex-end;
        .top_div_main_right_dash {
          display: flex;
          align-items: center;
        }
      }
    }

    .middle_div {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      flex-flow: column;
    }

    .middle_div_generate_button {
      width: 95%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    .bottom_div {
      // position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 10px !important;
      .main_paper {
        width: 95% !important;
        max-height: 200px !important;
        overflow: auto !important;
        box-sizing: border-box !important;
      }
      .content_list {
        padding-left: 15px !important;
        border-bottom: 1px solid gray;
        .edit_icon:hover {
          color: #0b9bef;
        }
        .delete_icon:hover {
          color: #ef1634;
        }
      }
      // .content_list:hover {
      //     background-color: #a885e6!important;
      //     color: white!important;
      //     cursor: pointer!important;
      // }
    }
  }
`

const CustomWrapperInit = styled.div`
  // min-height: 100vh;
  @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`
export default Campaign
