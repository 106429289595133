import { useState, useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { useLocation } from "react-router-dom";
import { FooterWrapper, FooterBgImg, FooterBgImgCont, BoldSpan, CustomSpan } from './style'
import styled from 'styled-components'
import { Grid, FormGroup, Input, Button } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import history from 'modules/app/components/history'

const Footer = (props: any) => {
    const selector = useSelector((state: any) => state)
    const dispatch = useDispatch()
    useEffect(() => {
        
      }, [])

    const { pathname } = useLocation();
    // console.log(pathname);
    if (pathname === "/signin") return null;

    return (
        <FooterContainer>
            <CustomGrid container spacing={0} sx={{paddingTop: '10px'}}>
              <CustomGrid item sm={12} md={2}>

              </CustomGrid>
              <CustomGrid item sm={12} md={8} className="mid_div">
                <div>
                  Sample text. Click to select the text box. Click again or double click to start editing the text.
                </div>
              </CustomGrid>
              <CustomGrid item sm={12} md={2}>

              </CustomGrid>
              
            </CustomGrid>
        </FooterContainer>
    );
};


const FooterContainer = styled.div`
//   @media (max-width: ${screenSizes.mediaM}px) {
//     display: none;
//   }
  background-color: #333333!important;
  color: white!important;

`
const CustomGrid = styled(Grid)`
  &.mid_div {
    display: flex!important;
    justify-content: center!important;
    text-align: center!important;
    padding: 30px 30px!important;
}
`

const mapStateToProps = (state: any) => ({
    
})


export default connect(mapStateToProps)(Footer);