import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Button, Box, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'

interface Props {
    item: any,
}

const ProductBoxMobile = (props: Props) => {
    const { item } = props;
    // console.log(item)
    // const selector = useSelector((state: any) => state)
    // const [ detailPageUrl, setDetailPageUrl ] = useState<string>("")
    // useEffect(() => {
    //     setDetailPageUrl(''.replace('?tag=revisions05-20', '?tag=' + localStorage.getItem('amazon_tracking_id')))
    // }, [localStorage.getItem('amazon_tracking_id')])

    // const recordIds = () => {
    //     // console.log(selector.gclid.currentGclid)
    //     // console.log(localStorage.getItem('amazon_tracking_id'))
    //     // recordUserAndIds(selector.gclid.currentGclid, localStorage.getItem('amazon_tracking_id'), ''.replace('?tag=revisions05-20', '?tag=' + localStorage.getItem('amazon_tracking_id')))
        
    // }

    return (
        <CustomDiv className="container_div">
            <CustomBox className="parent_box">
                <CustomBox className="middle_box">
                    <Box className="main_box">
                        <Box className="part_box1">
                            <Box className="part_box1_right date">{item.date}</Box>
                        </Box>
                        <CustomDiv>
                            <Box className="part_box2 campaign">{item.campaign}</Box>
                            <Box className="part_box2">searches: {item.searches}</Box>
                            <Box className="part_box2">clicks: {item.clicks}</Box>
                            <Box className="part_box2">estimated_revenue: {item.estimated_revenue}</Box>
                            <Box className="part_box2">revenue_per_click: {item.revenue_per_click}</Box>
                            <Box className="part_box2">cost: {item.cost}</Box>
                            <Box className="part_box2">profit: <Customspan className={item.profit < 0 ? 'red' : 'green'}>{item.profit}</Customspan></Box>
                            <Box className="part_box2">last_updated: {'\n' + item.last_updated}</Box>
                        </CustomDiv>
                    </Box>
                </CustomBox>
                <Divider sx={{ boxShadow: '0px 0px 0px #0a0909'}} />
                <CustomBox className="bottom_box">
                </CustomBox>
            </CustomBox>
        </CustomDiv>
    )
}

const Customspan = styled.span`
    &.red {
        color: red;
    }
    &.green {
        color: green;
    }
`

const CustomDiv = styled.div`
    &.container_div{
        // text-decoration: none;
        width: 100%;
        &:hover {
            background-color: #edecec;
        }
    }
`
const CustomButton = styled(Button)`
`
const CustomBox = styled(Box)`
    width: 100%;
    // height: 350px;
    display: flex;
    flex-direction: column;
    // background: white;
    margin: 3px 0;
    border-radius: 10px;
    // background: aqua;
    position: relative;
    box-sizing: border-box;
    .top_box {
        // background: white;
        margin: 0px 0px;
        // width: 180px;
        display: flex!important;
        align-items: center!important;
        justify-content: start!important;
        padding: 10px 0 5px 0;
    }
    .middle_box {
        // background: white;
        margin: 0px 0px;
        // width: auto;
        // flex-grow: 1;
        display: flex!important;
        align-items: center!important;
        justify-content: start!important;
        // padding-left: 30px;
        // padding-right: 30px;
        padding: 5px 10px;
        .main_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .part_box1{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                // margin-bottom: 3px;
                
                .part_box1_right {
                    font-family: sans-serif;
                    // font-weight: 700;
                    line-height: 1.33;
                    font-size: 14px;
                    letter-spacing: 0.01em;
                    // color: #0f1a16;
                    color: #1275bb;
                }
            }

            .part_box2{
                margin: 0px;
                // color: #1275bb;
                color: #0f1a16;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: 14px;
                font-family: Inter, Arial, sans-serif;
                // font-weight: 600;
                // cursor: pointer;
            }

            .date {
                margin-left: 5px;
            }

            .campaign {
                font-weight: 500;
            }
        }
    }
    .bottom_box {
        // background: white;
        margin: 0px 0px;
        display: flex!important;
        align-items: center!important;
        justify-content: end!important;
        padding: 10px 10px;
        img {
            width: 133px;
            height: 24px;
            margin-top: 10px;
            // margin-bottom: 12px;
            }
        .MuiBox-root {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    
    ${CustomButton} {
        width: 100%;
        padding: 10px;
        cursor: pointer;
        font-family: sans-serif;
        font-weight: 600;
        line-height: 1.33;
        font-size: 15px!important;
        letter-spacing: 0.03em!important;
        color: white!important;
        background-color: #37a4f1!important;
        border: 1px solid #107ebf!important;
        
        &:hover {
            background-color: #107ebf!important;
        }  
    }
`

export default ProductBoxMobile