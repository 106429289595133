import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { publish_post } from 'modules/api/market-api'
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import styled from 'styled-components'

// const itemData = [
//   {
//     img: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
//     title: 'Bed',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
//     title: 'Books',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
//     title: 'Sink',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
//     title: 'Kitchen',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
//     title: 'Blinds',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
//     title: 'Chairs',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
//     title: 'Laptop',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
//     title: 'Doors',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
//     title: 'Coffee',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
//     title: 'Storage',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
//     title: 'Candle',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
//     title: 'Coffee table',
//   },
// ];

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected == true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function ImgDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ selectedImgIndex, setSelectedImgIndex ] = React.useState<number>(0);
  const [ selectedImgSrc, setSelectedImgSrc ] = React.useState<string>('');
  const [ itemData, setItemData ] = React.useState<any>([]);

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    // console.log(props.imgArr)
    setItemData(props.imgArr)
  }, [props.imgArr])

  const handleClose = () => {
    props.setOpen(false);
    // props.setOpen(false)
  };

  const handleSave = () => {
    props.setImg(selectedImgSrc)
    props.setOpen(false);
  };

  const handleSelectedImgIndex = (event: any, index: number, src: string) => {
    // console.log(event.detail);
    switch (event.detail) {
      case 1: {
        // console.log('single click');
        // console.log(index)
        // console.log(src)
        setSelectedImgIndex(index)
        setSelectedImgSrc(src)
        break;
      }
      case 2: {
        // console.log('double click');
        props.setImg(src)
        props.setOpen(false);
        break;
      }
      case 3: {
        // console.log('triple click');
        break;
      }
      default: {
        break;
      }
    }
    
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Choose Image</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%', height: 450, overflowY: 'scroll' }}>
            <ImageList variant="masonry" cols={3} gap={8}>
              {itemData? itemData.map((item: any, index: number) => (
                <CustomDiv key={index} selected={ index == selectedImgIndex ? true : false}>
                  <CustomImageListItem onClick={(e) => {handleSelectedImgIndex(e, index, item.img)}} >
                    <img
                      src={`${item.img}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </CustomImageListItem>
                </CustomDiv>
              )) : <></>}
            </ImageList>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Select</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const CustomImageListItem = styled(ImageListItem)`
    
`