import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from 'shared/styles/theme'
import Routes from './components/routes/Routes'
import setAuthToken from 'logic/utils/setAuthToken'

if(localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken)
}

const App = () => {
  const selector = useSelector((state: any) => state)
  const dispatch = useDispatch()

  useEffect(() => {
    // localStorage.setItem('amazon_tracking_id', 'bbb')
    // localStorage.removeItem('amazon_tracking_id')
    // console.log(localStorage.getItem('amazon_tracking_id'))
  })

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes />
    </ThemeProvider>
  )
}

export default App
