import * as React from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export interface State extends SnackbarOrigin {
    open: boolean;
}
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
export default function CustomSnackbar(props:any) {

    const handleClose = () => {
        setState({ ...state, open: false });
        props.setShow(false)
    };
    React.useEffect(() => {
        // console.log(props.show)
        if(props.show == true) {
            setState({ open: true, vertical: 'top', horizontal: 'center', });
        }
    }, [props.show])
    
    const [state, setState] = React.useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;
    
    return (
        <div>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            // message="The user information is not correct!"
            key={vertical + horizontal}
          >
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                The user information is not correct!
            </Alert>
          </Snackbar>
        </div>
      );
}